/*// EXERCICE //////////////////////////////////////////////////////////////////////////////*/
.form_exercice_label_etat_cloture,
.form_exercice_label_date_fin,
.form_exercice_label_date_debut,
.form_exercice_label_appellation {	
	vertical-align:top;
}
//	display:inline-block;
//	vertical-align:top;
//	margin:5px;
//  width: 120px;
//  text-align: center;

	
.form_exercice_label_etat_cloture,
.form_exercice_label_date_fin,
.form_exercice_label_date_debut,
.form_exercice_label_appellation,
{	
	width:340px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_exercice_input_date_fin,
.form_exercice_input_date_debut {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:80px;
	text-align:center; 
}

.form_exercice_input_appellation {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:150px;
	text-align:center; 
}
//	display:block;
//	margin: 0 auto;
//    line-height: 25px;
//    height:@input_height_2;
//	padding: 3px;
//	text-align:center;
//	width:85px;
/*////////////////////////////////////////////////////////*/
.form_exercice_input_etat_cloture {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:110px;
	text-align:center; 
}

.form_exercice_label_id_appellation {
	width:370px;//200px;
	display:inline-block;
	vertical-align:top;
	margin-bottom:5px;
	//text-align:center; 
}
.form_exercice_input_id_appellation {
	display:inline-block;
	margin: 0 auto 5px;
	height:@input_height_2;
	font-size:1.1em; 
	width:200px;
	text-align:center; 
}
/*// EXERCICE //////////////////////////////////////////////////////////////////////////////*/




