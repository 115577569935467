/*// JOURNAL //////////////////////////////////////////////////////////////////////////////*/
.form_journal_label_journal_code,
.form_journal_label_journal_nom,
.form_journal_label_description,
.form_journal_label_cpte_central,
.form_journal_label_nom_numerotation,
.form_journal_label_num_commune,
//.form_journal_label_date_last_ecrit,
.form_journal_label_last_num_ecrit,
.form_journal_label_next_num_ecrit {	
	vertical-align:top;
}
.form_journal_label_journal_code,
.form_journal_label_journal_nom,
.form_journal_label_description,
.form_journal_label_cpte_central,
.form_journal_label_nom_numerotation,
//.form_journal_label_num_commune,
.form_journal_label_next_num_ecrit,
//.form_journal_label_date_last_ecrit,
.form_journal_label_last_num_ecrit 
{	
	width:500px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_journal_input_journal_code,
//.form_journal_input_date_last_ecrit,
.form_journal_input_last_num_ecrit,
.form_journal_input_next_num_ecrit {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:80px;
	text-align:center; 
}
.form_journal_input_cpte_central {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:120px;
	text-align:center; 
}
.form_journal_input_description,
.form_journal_input_journal_nom,
//.form_journal_input_num_compte,
.form_journal_input_nom_numerotation {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:300px;
	text-align:center; 
}
/*.form_journal_input_num_commune {
	display:inline-block;
	margin: 0 auto;
	height:15px;
	font-size:1.1em; 
	width:30px !important;
	text-align:center; 
}*/
/*////////////////////////////////////////////////////////*/
#form_journal_input_group_cpte_central {
 	display:inline-table;
 	width:120px;
}
legend {
    font-size:1em;
    font-weight:bold;
    border-width: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    display: inline-block;
    vertical-align:top;
    width:200px;
}
// SELECTIONS POUR PDF ///////////////////////////////////////////////////////////////////////
#form_pdf_div_haut,
#form_pdf_div_milieu_1,
#form_pdf_div_bas {
	border-collapse:separate;
	border-spacing:43px 0px;
}
#form_pdf_div_haut_gauche,
#form_pdf_div_haut_droit,
#form_pdf_div_milieu_1_gauche,
#form_pdf_div_milieu_1_droit,
#form_pdf_div_bas_gauche,
#form_pdf_div_bas_droit, {
	width:360px;
	display:table-cell;
	padding:10px;
	text-align:center;
}




.form_journaux_label_id_journal_code,
.form_journaux_selectionner_label_mois {
	vertical-align:top;
	width:340px;
}
.form_journaux_input_id_journal_code,
.form_journaux_selectionner_input_mois{
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:90px;
	text-align:center; 
}
/*// JOURNAL //////////////////////////////////////////////////////////////////////////////*/




