#rapport_contenu {
	position:fixed;
	top:50px;
	left:150px;
	width:230px;
	height:600px;
	background-color:@bleu_clair_2;
	border: 1px solid @bleu_1;
	padding:20px;
	opacity: 1;
}

#rapport_titre {
	font-size:1.2em;
	text-align:center;
}

[id^="rapport_flag_"] {
	font-size:0.8em;
	display:inline-block;
	margin-left:10px;
	padding:0px 5px;
	cursor:pointer;
}
.carre_couleur_a {
	background-color: #ff0000;//#dd1616;//#d11818;	// @rouge_sombre;
	color: #ff0000;//#dd1616;//#d11818;	// @rouge_sombre;
}
.carre_couleur_b {
	background-color:@vert_fluo;
	color:@vert_fluo;
}
#rapport_input_chkx {
	display:inline-block;
	margin-left:10px;
	vertical-align:bottom;
}

#rapport_span {
	font-size:1.1em;
	font-style:italic;
}

#rapport_drag_drop {
	cursor:all-scroll;
}
#rapport_fermer {
	cursor:pointer;
}
#rapport_afficher {
	cursor:pointer;
}