/*// FORM VENTES JOURNAUX PC //////////////////////////////////////////////////////////////////////////////*/
.form_journal_creer_div1 {
	width: 850px;
}
.form_journal_creer_div2 {
	width: 550px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_op_comm_div1,
.form_retours_div1,
.form_receptions_div1,
.form_commandes_div1,
.form_taille_div1,
.form_grille_taille_div1,
.form_type_article_div1,
.form_categorie_article_div1,
.form_saison_div1,
.form_client_div1,
.form_frais_div1,
.form_fournisseur_div1,
.form_categorie_fournisseur_div1,
.form_jours_feries_div1,
.form_num_ecrit_type_creer_div1,
.form_rapp_bq_creer_div1,
.form_exercice_creer_div1,
.form_tva_creer_div1,
.form_journal_creer_div1,
.form_param_creer_div1,
.form_pc_div1,
.form_vente_enreg_div1,
.form_user_enreg_div1 {
	font-size:1.2em;
	margin:auto;
	border:2px solid @bleu_1;
	background-color:@white;
}

.form_retours_div1,
.form_receptions_div1,
.form_commandes_div1,
.form_taille_div1,
.form_grille_taille_div1,
.form_type_article_div1,
.form_categorie_article_div1,
.form_saison_div1 {
	width:500px;
}
.form_rapp_bq_creer_div1,
.form_exercice_creer_div1,
.form_tva_creer_div1 {
	width:500px;
}
.form_frais_div1 {
	width:550px;
}
.form_op_comm_div1,
.form_client_div1,
.form_fournisseur_div1 {
	width:900px;//550px;
}
.form_categorie_fournisseur_div1 {
	width:550px;
}
.form_param_creer_div1 {
	width:790px;
}
.form_pc_div1 {
	width:650px;
}
.form_vente_enreg_div1 {
	width:390px;
}
.form_num_ecrit_type_creer_div1 {
	width:500px;
}
.form_jours_feries_div1 {
	width:500px;
}
.form_user_enreg_div1 {
	width:500px;
}
.form_op_comm_titre,
.form_retours_titre,
.form_receptions_titre,
.form_commandes_titre,
.form_taille_titre,
.form_grille_taille_titre,
.form_type_article_titre,
.form_categorie_article_titre,
.form_saison_titre,
.form_client_titre,
.form_frais_titre,
.form_fournisseur_titre,
.form_categorie_fournisseur_titre,
.form_jours_feries_titre,
.form_num_ecrit_type_creer_titre,
.form_rapp_bq_creer_titre,
.form_exercice_creer_titre,
.form_tva_creer_titre,
.form_journal_creer_titre,
.form_param_creer_titre,
.form_pc_titre,
.form_vente_enreg_titre,
.form_user_enreg_titre {
	color:@bleu_fonce_1;
	text-align:center;
}
.form_journal_creer_div1 label > span,
.form_param_creer_div1 label > span {
	text-align:left;
	margin-left:20px;
}

.form_retours_div1 label > span,
.form_receptions_div1 label > span,
.form_commandes_div1 label > span,
.form_taille_div1 label > span,
.form_grille_taille_div1 label > span,
.form_type_article_div1 label > span,
.form_categorie_article_div1 label > span,
.form_saison_div1 label > span,
.form_client_div1 label > span,
.form_frais_div1 label > span,
.form_fournisseur_div1 label > span,
.form_categorie_fournisseur_div1 label > span,
.form_jours_feries_div1 label > span,
.form_rapp_bq_creer_div1 label > span,
.form_exercice_creer_div1 label > span,
.form_tva_creer_div1 label > span,
.form_num_ecrit_type_creer_div1 label > span,
.form_vente_enreg_div1 label > span,
.form_pc_div1 label > span,
.form_user_enreg_div1 > span {
	text-align:center;
}

.form_op_comm_div1 label > span,
.form_retours_div1 label > span,
.form_receptions_div1 label > span,
.form_commandes_div1 label > span,
.form_taille_div1 label > span,
.form_grille_taille_div1 label > span,
.form_type_article_div1 label > span,
.form_categorie_article_div1 label > span,
.form_saison_div1 label > span,
.form_client_div1 label > span,
.form_frais_div1 label > span,
.form_fournisseur_div1 label > span,
.form_categorie_fournisseur_div1 label > span,
.form_jours_feries_div1 label > span,
.form_rapp_bq_creer_div1 label > span,
.form_exercice_creer_div1 label > span,
.form_tva_creer_div1 label > span,
.form_num_ecrit_type_creer_div1 label > span,
.form_journal_creer_div1 label > span,
//.form_param_creer_div1 label > span,
.form_pc_div1 label > span,
.form_vente_enreg_div1 label > span {
	font-weight:600;
	display:inline-block;
	width:170px;
}
/*.form_frais_div1 label > span,
.form_fournisseur_div1 label > span,
.form_categorie_fournisseur_div1 label > span,
.form_jours_feries_div1 label > span,
.form_tva_creer_div1 label > span,
.form_num_ecrit_type_creer_div1 label > span,
.form_journal_creer_div1 label > span,
//.form_param_creer_div1 label > span,
.form_pc_div1 label > span,
.form_vente_enreg_div1 label > span {
	font-weight:600;
	display:inline-block;
	width:170px;
}*/
.form_param_creer_div1 label > span,
.form_user_enreg_div1 label > span {
	font-weight:600;
	display:inline-block;
	width:180px;
}

.form_param_creer_div1 .form_param_label_theme_couleur > span {
	font-weight:600;
	display:inline-block;
	width:100px;
}



.form_op_comm_div2,
.form_retours_div2,
.form_receptions_div2,
.form_commandes_div2,
.form_taille_div2,
.form_grille_taille_div2,
.form_type_article_div2,
.form_categorie_article_div2,
.form_saison_div2,
.form_client_div2,
.form_frais_div2,
.form_fournisseur_div2,
.form_categorie_fournisseur_div2,
.form_jours_feries_div2,
.form_num_ecrit_type_creer_div2,
.form_rapp_bq_creer_div2,
.form_exercice_creer_div2,
.form_tva_creer_div2,
.form_journal_creer_div2,
.form_param_creer_div2,
.form_pc_div2,
.form_vente_enreg_div2,
.form_user_enreg_div2 {
	margin:auto;
	margin-bottom:5px;
	border:1px solid @bleu_1;
	padding:5px;
}
.form_retours_div2,
.form_receptions_div2,
.form_commandes_div2,
.form_taille_div2,
.form_grille_taille_div2,
.form_type_article_div2,
.form_categorie_article_div2,
.form_saison_div2 {
	width:360px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_frais_div2 {
	width:450px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_op_comm_div2,
.form_client_div2,
.form_fournisseur_div2 {
	width:880px;//450px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_categorie_fournisseur_div2 {
	width:450px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_exercice_creer_div2,
.form_tva_creer_div2 {
	width:360px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_rapp_bq_creer_div2,
.form_jours_feries_div2 {
	width:400px;
	background-color:@bleu_clair_3;
}
.form_num_ecrit_type_creer_div2 {
	width:480px;
	background-color:@bleu_clair_3;
}
.form_param_creer_div2 {
	width:770px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_pc_div2 {
	width:470px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_vente_enreg_div2 {
	width:370px;
	background-color:@bleu_clair_3;
}
.form_user_enreg_div2 {
	width:480px;
	background-color:@bleu_clair_3;
}

.form_op_comm_div3,
.form_retours_div3,
.form_receptions_div3,
.form_commandes_div3,
.form_taille_div3,
.form_grille_taille_div3,
.form_type_article_div3,
.form_categorie_article_div3,
.form_saison_div3,
.form_client_div3,
.form_frais_div3,
.form_fournisseur_div3,
.form_categorie_fournisseur_div3,
.form_vente_enreg_div3,
.form_jours_feries_div3,
.form_num_ecrit_type_creer_div3,
.form_rapp_bq_creer_div3,
.form_exercice_creer_div3,
.form_tva_creer_div3,
.form_journal_creer_div3,
.form_param_creer_div3,
.form_pc_div3,
.form_user_enreg_div3 {
	margin:10px;
	vertical-align:super;
	text-align:left;
}
.form_op_comm_div3 ul li,
.form_retours_div3 ul li,
.form_receptions_div3 ul li,
.form_commandes_div3 ul li,
.form_taille_div3 ul li,
.form_grille_taille_div3 ul li,
.form_type_article_div3 ul li,
.form_categorie_article_div3 ul li,
.form_saison_div3 ul li,
.form_client_div3 ul li,
.form_frais_div3 ul li,
.form_fournisseur_div3 ul li,
.form_categorie_fournisseur_div3 ul li,
.form_jours_feries_div3 ul li,
.form_num_ecrit_type_creer_div3 ul li,
.form_rapp_bq_creer_div3 ul li,
.form_exercice_creer_div3 ul li,
.form_tva_creer_div3 ul li,
.form_journal_creer_div3 ul li,
.form_param_creer_div3 ul li,
.form_vente_enreg_div3 ul li,
.form_pc_div3 ul li,
.form_user_enreg_div3 ul li {
	/* messages du formulaire en rouge */
	color:@rouge_sombre;
}
.form_op_comm_submit,
.form_retours_submit,
.form_receptions_submit,
.form_commandes_submit,
.form_taille_submit,
.form_grille_taille_submit,
.form_type_article_submit,
.form_categorie_article_submit,
.form_saison_submit,
.form_client_submit,
.form_frais_submit,
.form_fournisseur_submit,
.form_categorie_fournisseur_submit,
.form_jours_feries_submit,
.form_num_ecrit_type_creer_submit,
.form_rapp_bq_creer_submit,
.form_exercice_creer_submit,
.form_tva_creer_submit,
.form_journal_creer_submit,
.form_param_creer_submit,
.form_vente_enreg_submit,
.form_pc_submit,
.form_user_enreg_submit {
	text-align:center;
	padding-top:15px;	
	padding-bottom:5px;	
}
.form_article_submit,
.form_creances_submit,
.form_ventes_submit {
	text-align:center;
	padding-top:1px;	
	padding-bottom:1px;	
}
.form_cpt_ecrit_div_submit,	
.form_gs_enreg_div_submit {	
	text-align:center;
	margin:10px;
	margin-top:0px;
	padding-bottom:5px;	
}

.form_op_comm_submit input,
.form_retours_submit input,
.form_receptions_submit input,
.form_commandes_submit input,
.form_article_submit input,
.form_taille_submit input,
.form_grille_taille_submit input,
.form_gs_enreg_div_submit input,
.form_type_article_submit input,
.form_categorie_article_submit input,
.form_cpt_ecrit_div_submit input,
.form_saison_submit input,
.form_client_submit input,
.form_frais_submit input,
.form_fournisseur_submit input,
.form_categorie_fournisseur_submit input,
.form_pc_submit input,
.form_jours_feries_submit input,
.form_num_ecrit_type_creer_submit input,
.form_rapp_bq_creer_submit input,
.form_exercice_creer_submit input,
.form_tva_creer_submit input,
.form_journal_creer_submit input,
.form_param_creer_submit input,
.form_creances_submit input,
.form_ventes_submit input,
.form_vente_enreg_submit input,
.form_user_enreg_submit input {
	width:100px;//120px;
}
.form_op_comm_submit input,
.form_retours_submit input,
.form_receptions_submit input,
.form_commandes_submit input,
.form_article_submit input,
.form_taille_submit input,
.form_gs_enreg_div_submit input,
.form_grille_taille_submit input,
.form_type_article_submit input,
.form_categorie_article_submit input,
.form_saison_submit input,
.form_client_submit input,
.form_cpt_ecrit_div_submit input,
.form_frais_submit input,
.form_fournisseur_submit input,
.form_categorie_fournisseur_submit input,
.form_jours_feries_submit input,
.form_num_ecrit_type_creer_submit input,
.form_rapp_bq_creer_submit input,
.form_exercice_creer_submit input,
.form_tva_creer_submit input,
.form_journal_creer_submit input,
.form_param_creer_submit input,
.form_pc_submit input,
.form_creances_submit input,
.form_ventes_submit input,
.form_vente_enreg_submit input,
.form_user_enreg_submit input {
	.bg_button(@bleu_clair_1);
	height:@input_height_2;
	line-height:1;
}

.form_op_comm_submit input:hover,
.form_retours_submit input:hover,
.form_receptions_submit input:hover,
.form_commandes_submit input:hover,
.form_article_submit input:hover,
.form_categorie_article_submit input:hover,
.form_categorie_fournisseur_submit input:hover,
.form_client_submit input:hover,
.form_cpt_ecrit_div_submit input:hover,
.form_fournisseur_submit input:hover,
.form_frais_submit input:hover,
.form_grille_taille_submit input:hover,
.form_gs_enreg_div_submit input:hover,
.form_journal_creer_submit input:hover,
.form_jours_feries_submit input:hover,
.form_num_ecrit_type_creer_submit input:hover,
.form_param_creer_submit input:hover,
.form_pc_submit input:hover,
.form_saison_submit input:hover,
.form_taille_submit input:hover,
.form_rapp_bq_creer_submit input:hover,
.form_exercice_creer_submit input:hover,
.form_tva_creer_submit input:hover,
.form_type_article_submit input:hover,
.form_user_enreg_submit input:hover,
.form_vente_enreg_submit input:hover,
.form_creances_submit input:hover,
.form_ventes_submit input:hover {
	.bg_button_hover(@bleu_1);
	height:@input_height_2;
	line-height:1;
	background-position:0px;
}
input#achats_previsionnel_dates_input_submit,
input#avs_ws_input_submit,
input#fec_creer_input_submit,
input#declaration_tva_input_submit,
input#journal_central_afficher_input_submit,
input#ecriture_rechercher_input_submit,
input#gd_livre_input_submit,
input#ecrit_a_valider_input_submit,
input#lettrage_afficher_input_submit,
input#rapp_bancaire_input_submit,
input#delettrage_input_submit,
input#balance_input_submit,
input#lettrage_input_submit,
input#journaux_afficher_input_submit,
input#rapp_bancaire_input_submit,
input#ventes_marque_saison_dates_input_submit,
input#form_article_attrib_num_retour_input_submit,
input#form_article_attrib_num_cde_input_submit,
input#form_article_attrib_num_recept_input_submit {
	.bg_button(@bleu_clair_1);
	height:@input_height_2;
	line-height:1;
}
input#declaration_tva_pdf_submit,
input#stock_pdf_submit,
input#gd_livre_pdf_submit,
input#balance_pdf_submit,
input#journaux_afficher_pdf_submit,

input#fec_creer_input_submit,
input#declaration_tva_input_submit,
input#journal_central_afficher_input_submit,
input#ecriture_rechercher_input_submit,
input#gd_livre_input_submit,
input#rapp_bancaire_input_submit,
input#journaux_afficher_input_submit,
input#delettrage_input_submit,
input#balance_input_submit,
input#lettrage_input_submit,
input#ecrit_a_valider_input_submit,
input#lettrage_afficher_input_submit,
input#rapp_bancaire_input_submit {
	margin-bottom:5px;
}
input#achats_previsionnel_dates_input_submit:hover,
input#avs_ws_input_submit:hover,
input#fec_creer_input_submit:hover,
input#declaration_tva_input_submit:hover,				
input#journal_central_afficher_input_submit:hover,	
input#ecriture_rechercher_input_submit:hover,		
input#gd_livre_input_submit:hover,
input#rapp_bancaire_input_submit:hover,
input#delettrage_input_submit:hover,
input#balance_input_submit:hover,
input#lettrage_input_submit:hover,
input#ecrit_a_valider_input_submit:hover,
input#lettrage_afficher_input_submit:hover,
input#ecrit_a_valider_input_submit:hover,
input#journaux_afficher_input_submit:hover,
input#rapp_bancaire_input_submit:hover,
input#ventes_marque_saison_dates_input_submit:hover,
input#form_article_attrib_num_retour_input_submit:hover,
input#form_article_attrib_num_cde_input_submit:hover,
input#form_article_attrib_num_recept_input_submit:hover {
	.bg_button_hover(@bleu_1);
	height:@input_height_2;
	line-height:1;
	background-position:0px;
}
	//PDF
input#declaration_tva_pdf_submit,
input#stock_pdf_submit,
input#journal_central_pdf_submit,
input#journaux_afficher_pdf_submit,
input#gd_livre_pdf_submit,
input#balance_pdf_submit,
input#form_client_creer_adresse_pdf_submit,
input#form_article_creer_etiq_article_pdf_submit {
	.bg_button(@rouge_sombre);
	height:@input_height_2;
	line-height:1;
}
	//PDF
input#declaration_tva_pdf_submit,
input#stock_pdf_submit:hover,
input#journal_central_pdf_submit:hover,
input#journaux_afficher_pdf_submit:hover,
input#gd_livre_pdf_submit:hover,
input#balance_pdf_submit:hover,
input#form_client_creer_adresse_pdf_submit:hover,
input#form_article_creer_etiq_article_pdf_submit:hover {
	.bg_button_hover(@rouge);
	height:@input_height_2;
	line-height:1;
	background-position:0px;
	color:@rouge;
}
/*// FORM VENTES JOURNAUX PC //////////////////////////////////////////////////////////////////////////////*/
// INVENTAIRE
.inventaire_input_modif_stock {
	display:inline-block;
	height:@input_height_2;
	font-size:1.1em;
	font-weight:bold;
	width:40px;
	text-align:center; 
}
// INVENTAIRE
