/*// DEPOTS_BANQUE //////////////////////////////////////////////////////////////////////////////*/
/*.input_depot_ref {
	display:inline-block;
	margin:0 auto;
	//margin-left:450px;
	margin-bottom:5px;
	height:@input_height_2;
	font-size:1.3em;
	font-weight:bold;
	text-align:center;
	width:100px;
}*/
#form_div_cb_a_deposer,
#form_div_ch_a_deposer,
#form_div_esp_a_deposer {
	text-align:left;
	margin-top:10px;
	margin-left:30px;	
}
#form_a_cb_a_deposer,
#form_a_ch_a_deposer,
#form_a_esp_a_deposer {
	cursor:pointer;
}
#form_div_cb_depot,
#form_div_ch_depot,
#form_div_esp_depot {
	text-align:left;
	margin-top:10px;
	margin-left:30px;	
}
#form_a_cb_depot,
#form_a_ch_depot,
#form_a_esp_depot {
	cursor:pointer;
}
.vers_detail_depot {
	cursor: pointer;
	text-decoration: underline;
}
#a_depot_mode_paiement_modifier {
	cursor: pointer;
	display:inline-block;

	//text-decoration: underline;
	
}
[class^="depot_a_faire_input_date_"],
[class^="depot_a_faire_input_num_"],
[class^="depot_a_faire_input_cbchesp_"] {
	text-align:center;
	width:70px;
	font-size:1.2em;
	font-weight:bold;
}
[class^="depot_a_faire_input_date_"] {
	width:70px;
}
[class^="depot_a_faire_input_num_"] {
	width:20px;
}
[class^="depot_a_faire_input_cbchesp_"] {
	width:30px;
}
.conteneur_depot_bq_refresh {
	margin:auto;
	text-align:right;
	width:900px;
	border:1px solid beige;
	font-size:1.1em;
}

/*// DEPOTS_BANQUE //////////////////////////////////////////////////////////////////////////////*/
