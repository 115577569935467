/*// JOURS FERIES //////////////////////////////////////////////////////////////////////////////*/
.form_jours_feries_label_nom,
.form_jours_feries_label_date  {
   display: inline-block;
	vertical-align:top;
	width:350px;
	margin-right:10px;
	margin-left:10px;
}

.form_jours_feries_input_nom,
.form_jours_feries_input_date {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
}
.form_jours_feries_input_nom {
  width: 150px;
}
.form_jours_feries_input_date {
  width: 80px;
}
/*// JOURS FERIES //////////////////////////////////////////////////////////////////////////////*/
