#table_declar_tva_ecritures {
	border:1px solid @bleu_fonce_3;
	width:600px;
	margin:10px auto;
	color:@bleu_fonce_3;
	.font_size_1_2;
	tr {
		height:25px;	
	}
	.tr_titres_declar_tva_ecritures {
		.text_align_center;
		.font_size_1_1;
		//.font_bold;
		.font_italic;
		background-color: @bleu_clair_6;
	}
	th {
		.text_align_center;
		 .font_size_1_2;
		 //.font_bold;	
		 background-color: @bleu_clair_1;
	}
	.td_tva_col_num {
		background-color: #f8dcd3;
		width:55px;
		font-weight:bold;
		text-align:center;
	}
}	


#table_declar_tva_liste_cptes {
	//margin-top:10px;
	width:600px;
	margin:10px auto;
	border:1px solid @bleu_fonce_3;
	.font_size_1_1;
	th {
		.td_tva_ss_titre;
		.text_align_center;
		height:60px;
	}
	tr {
		border:1px solid @bleu_fonce_3;
		height:27px;
		.font_size_1_1;
		td:first-child {
			.td_tva_col_num;
		}
	}
	td span {
		.span_tva_rubrique;
	}
	.td_tva_col_num {
		background-color: #f8dcd3;
		width:55px;
		font-weight:bold;
		text-align:center;
	}
}
#table_declar_tva {
	width:1000px;
	margin:10px auto;
	border:1px solid @bleu_fonce_3;
	.font_size_1_2;
	tr {
		border:1px solid @bleu_fonce_3;
		height:29px;
	}
	.td_tva_col_num {
		background-color: #f8dcd3;
		width:36px;
		font-weight:bold;
		text-align:center;
	}
	th {
		.td_tva_titre;
		span {
			 .span_tva_titre;
		}
	}
}

.td_tva_titre {
	background-color: @bleu_1;
	color:#fff;
	.font_size_1_2;
	font-weight:bold;
}
.td_tva_ss_titre {
	background-color: @bleu_clair_1;
	.font_size_1_2;
	font-weight:bold;
}
.td_tva_ss_titre_2 {
	background-color: @bleu_clair_6;
	.font_size_1_1;
	font-weight:bold;
}
[id^="input_tva_"] {
	width:115px;
	height:23px;
	text-align:right;
}
 
.td_tva_input {
	text-align:center;
	font-weight:bold;
	.font_size_1_1;
}
 .span_tva_titre,
 .span_tva_ss_titre,
 .span_tva_ss_titre_2,
 .span_tva_rubrique {
 	margin-left:10px;
 }
 
 
 
 