/*// CHOIX ECRITURES //////////////////////////////////////////////////////////////////////////////*/
.choix_ecriture_div1 {
	font-size:1.1em;
	width:1000px;
	margin:auto;
	border:2px solid @bleu_1;
	background-color:@white;
}
.choix_ecriture_div1 nav {
	margin:auto;
	width:980px;
}
#choix_ecriture_nav .dropdown-menu > li {
	font-size :0.9em;
}
#ecriture-form input {
	width:70px;
}
/*// CHOIX ECRITURES //////////////////////////////////////////////////////////////////////////////*/
