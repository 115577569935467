/*// FORM CPT ECRIT //////////////////////////////////////////////////////////////////////////////*/
.titre_page > h4 {
	color:@bleu_fonce_1;
	text-align:center;
	font-size:1.4em;
}
.form_cpt_ecrit_titre {
	color:@bleu_fonce_1;
	text-align:center;
	
}
.form_cpt_ecrit_div1 {
	width:980px;/**/
	margin:auto;
	margin-top:20px;
	margin-bottom:20px;
	font-size:1.1em;
	border:2px solid @bleu_1;
	background-color:@white;
}
.form_cpt_ecrit_div2 {
	width:850px;
	margin:auto;
    margin-top: 20px;
	margin-bottom:20px;
	padding:5px;
    /*font-size: 1.1em;*/
    background-color: @bleu_clair_3;
    border: 1px solid @bleu_1;
}
.form_cpt_ecrit_div_JDDN {
	margin:10px;
	vertical-align:super;
	text-align:left;
}
.form_cpt_ecrit_div_NIcLDC {
	margin:10px;
	margin-top:30px;
	margin-bottom:0px;
}
.form_cpt_ecrit_div_buttons {
	margin-bottom:20px;
	text-align:right;
}
/*.button_enreg_new_ecrit_prog {
	float:left;
	font-style:italic;
	font-size:1em;
}*/
/*.button_add_ligne {
	font-style:italic;
	font-size:1em;
}*/
/*#fieldset_container {
	text-align:left;
}*/
/*////////////////////////////////////////////////////////*/
.form_cpt_ecrit_div_JDDN .form_categorie_fournisseur_label_id_cat,
.form_cpt_ecrit_label_valid_simul,
.form_cpt_ecrit_label_journal,
.form_cpt_ecrit_label_date_ecrit,
.form_cpt_ecrit_label_date_ech,
.form_cpt_ecrit_label_num_doc,
.form_cpt_ecrit_label_num_ecriture {
	display:inline-block;
	vertical-align:top;
	margin:5px;
}
.form_cpt_ecrit_label_journal {	
	width:120px;
    padding-right:17px;
}
.form_cpt_ecrit_label_date_ecrit
.form_cpt_ecrit_label_date_ech {	
  width: 120px;
  text-align: center;
}

.form_cpt_ecrit_label_num_ecriture {	
  width: 100px;//85px//120px;
  text-align: center;
}
.form_cpt_ecrit_label_num_doc {	
  width: 85px;//120px;
  text-align: center;
}
.form_cpt_ecrit_label_valid_simul {	
  width: 115px;//160px;
  text-align: center;
}
.form_cpt_ecrit_div_JDDN .form_categorie_fournisseur_label_id_cat,
 {	
  width: 130px;
  text-align: center;
}

/*////////////////////////////////////////////////////////*/
.form_gs_enreg_label_titre {	/*?????????*/
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  /*border:1px dashed #ff0000;*/
  /*chocolate*/
  /*text-align:center;*/
}
.form_gs_enreg_label_titre {	/*?????????*/
  width: 200px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_cpt_ecrit_div_JDDN .form_categorie_fournisseur_input_id_cat,
.form_cpt_ecrit_input_journal,
.form_cpt_ecrit_input_date_ecrit,
.form_cpt_ecrit_input_date_ech,
.form_cpt_ecrit_input_num_doc,
.form_cpt_ecrit_input_num_ecriture {
	display:block;
	margin: 0 auto;
    line-height: 25px;
    height:@input_height_2;
	padding: 3px;
	text-align:center;
}
.form_cpt_ecrit_input_valid_simul {
	display:block;
	margin: 0 auto;
    //line-height: 22px;
    height:@input_height_2;
	padding: 3px;
	text-align:center;
	font-size:0.99em;
}
.form_cpt_ecrit_input_journal {
    width: 60px !important;
}
.form_cpt_ecrit_input_date_ecrit,
.form_cpt_ecrit_input_date_ech,
.form_cpt_ecrit_input_num_doc,
.form_cpt_ecrit_input_num_ecriture {
	width:85px;
}
.form_cpt_ecrit_input_valid_simul {
	width:115px;
}
.form_cpt_ecrit_div_JDDN .form_categorie_fournisseur_input_id_cat,
 {
	width:130px;
}

/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_cpt_ecrit_div_num_compte,
.form_cpt_ecrit_div_intitule_compte,
.form_cpt_ecrit_div_libelle,
.form_cpt_ecrit_div_debit,
.form_cpt_ecrit_div_credit {
	display:inline-block;
	font-weight:bold;
	text-align:center;
	margin:5px;
}
.form_cpt_ecrit_div_num_compte {
	width:120px;
}
.form_cpt_ecrit_div_intitule_compte {
	width:230px;
}
.form_cpt_ecrit_div_libelle {
	width:180px;
}
.form_cpt_ecrit_div_debit,
.form_cpt_ecrit_div_credit {
	width:70px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_cpt_ecrit_label_num_compte,
.form_cpt_ecrit_label_intitule_compte,
.form_cpt_ecrit_label_libelle,
.form_cpt_ecrit_label_debit,
.form_cpt_ecrit_label_credit,
.form_cpt_ecrit_label_cross {	
	display:inline-block;
	vertical-align:top;
	margin:5px;
}
.form_cpt_ecrit_label_num_compte {	
	width:120px;
}
.form_cpt_ecrit_label_intitule_compte {	
	width:230px;
	font-size:0.9em;
}
.form_cpt_ecrit_label_libelle {	
	width:180px;
}	
.form_cpt_ecrit_label_debit,
.form_cpt_ecrit_label_credit {	
	width:70px;
}	
.form_cpt_ecrit_label_cross {
  width: 40px;
  text-align: center;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_cpt_ecrit_input_num_compte,
.form_cpt_ecrit_input_intitule_compte,
.form_cpt_ecrit_input_libelle,
.form_cpt_ecrit_input_debit,
.form_cpt_ecrit_input_credit {
	display:block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
}
.form_cpt_ecrit_input_num_compte {
	width:70px;
	text-align:center; 
}
.form_cpt_ecrit_input_intitule_compte {
	width:230px;
	text-align:left;
}
.form_cpt_ecrit_input_libelle {
	width:180px;
	text-align:left;
}
.form_cpt_ecrit_input_debit,
.form_cpt_ecrit_input_credit {
	width:70px;
	text-align:center; 
}
.cumul_C_div, .cumul_D_div {
    width:80px;
    display:table-cell;
    margin:5px;
    font-weight:bold;
    border:1px solid #BCBCBC;
    height:25px;
}
.cumul_CD_diff_div {
    width:80px;
    display:table-cell;
    margin:5px;
}
.container_cumul_CD_div {
    width:555px;
    display:table-cell;
    margin:5px;
}
/*// FORM CPT ECRIT //////////////////////////////////////////////////////////////////////////////*/
