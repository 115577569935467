/*// FRAIS-FOURNISSEUR-CATEGORIE //////////////////////////////////////////////////////////////////////////////*/
.form_fournisseur_label_marque,
.form_fournisseur_label_marque_abr,
.form_fournisseur_label_societe,
.form_fournisseur_label_contact,
.form_fournisseur_label_iban,
.form_fournisseur_label_bic {
	vertical-align:top;
	width:360px;//400px;
	//margin-right:10px;
	//margin-left:10px;
}
.form_article_div_gauche .form_fournisseur_label_marque {
	vertical-align:top;
	width:280px;
	text-align:left; 
	.form_fournisseur_input_marque {
		display:inline-block;
		margin: 0 auto;
		height:@input_height_2;
		font-size:1.1em; 
		width:150px;
		text-align:center; 
	}
}
.form_fournisseur_label_id_marque {
	vertical-align:top;
	width:280px;
	text-align:left; 
}
.form_frais_div1 .form_fournisseur_label_id_marque {
	margin-left: 10px;
    margin-right: 10px;
    vertical-align: top;
    width:400px;
}
.form_fournisseur_input_marque_abr,
.form_fournisseur_input_societe,
.form_fournisseur_input_contact,
.form_fournisseur_input_iban,
.form_fournisseur_input_bic {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:180px;
	text-align:center; 
}
.form_fournisseur_input_marque_abr {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:90px;
	text-align:center; 
}
.form_fournisseur_input_marque {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:180px;//150px;
	text-align:center; 
}
.form_fournisseur_input_id_marque {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:150px;
	text-align:center; 
}
/*// FRAIS-FOURNISSEUR-CATEGORIE //////////////////////////////////////////////////////////////////////////////*/
