/*// LISTES VENTES FREQ JOURNAUX //////////////////////////////////////////////////////////////////////////////*/
.container_23_taille,
.container_22_grille_taille,
.container_21_type_article,
.container_20_categorie_article,
.container_19_saison,
.container_12_jours_feries,
.container_11_num_ecrit_type,
.container_10_tva {
	width:500px;
}
.container_16_categorie_fournisseur {
	width:550px;
}
.container_14_frais {
	width:100%;
}
.container_6_pc {
	width:650px;
}
.container_8_gs_cat {
	width:770px;
}
.container_8_gs {
	width:770px;
	display:none;
}
.container_9_param {
	width:790px;
}
.container_7_journaux {
	width:850px;
}
.container_15_fournisseur,
.container_2_ventes_jour,
.container_36_depots_banque,
.container_37_ecrit_a_valider,
.container_35_avoirs,
.container_33_details_client,
.container_32_creances,
.container_34_op_comm,
.container_31_recherche,
.container_28_article_sans_num_recept,
.container_25_article_sans_num_cde,
.container_24_clients,
.container_18_article {
	width:900px;
}
.container_17_tresorerie,
.container_37_inventaire,
.container_13_users,
.container_51_rapp_bq,
.container_50_exercice,
.container_49_ecriture_rechercher,
.container_48_gd_livre,
.container_47_balance,
.container_46_lettrage,
.container_45_journaux_afficher,
.container_44_rapp_bancaire,
.container_43_avs_details_marque_types_saison,
.container_42_avs_details_types_marque_saison,
.container_41_avs_par_saison_marque,
.container_40_ventes_par_saison_marque,
.container_39_panier_marge,
.container_53_articles_avec_num_retour,
.container_52_articles_avec_num_recept,
.container_29_articles_avec_num_cde,
.container_30_retours,
.container_27_receptions,
.container_26_commandes,
.container_38_ventes_analyse {
	width:1000px;
}
.container_31_recherche,
.container_24_clients,
.container_18_article {
	display: none;
}


.container_table_tva,
.container_table_jours_feries
.container_table_num_ecrit_type,
.container_table_saison {
	width:480px;
}
.container_table_categorie_fournisseur {
	width:530px;
}
.container_table_frais {
	width:1120px;
}
.container_table_pc {
	width:630px;
}
.container_table_gs,
.container_table_gs_cat {
	width:750px;
}
.container_table_param {
	width:770px;
}
.container_table_journaux {
	width:830px;
}
.container_table_details_client,
.container_table_depots_banque,
.container_table_ecrit_a_valider,
.container_table_avoirs,
.container_table_creances {
	width:850px;
}
.container_table_fournisseur,
.container_table_ventes_jour,
.container_table_article_sans_num_cde
.container_table_op_comm,
.container_table_clients,
.container_table_article {
	width:880px;
}
.container_table_images,
.container_table_retours,
.container_table_receptions,
.container_table_commandes,
.container_table_rapp_bq,
.container_table_exercice,
.container_table_ecriture_rechercher,
.container_table_gd_livre,
.container_table_balance,
.container_table_lettrage,
.container_table_journaux_afficher,
.container_table_rapp_bancaire,
.container_table_ventes_par_marque_saison,
.container_table_avs_details_marque_types_saison,
.container_table_avs_details_types_marque_saison,
.container_table_avs_par_saison_marque,
.container_table_ventes_par_saison_marque,
.container_table_panier_marge,
.container_table_ventes_analyse,
.container_table_tresorerie,
.container_table_articles_avec_num_retour,
.container_table_articles_avec_num_recept,
.container_table_article_avec_num_cde,
.container_table_inventaire,
.container_table_users {
	width:980px;
}
.container_table_client {
	width:370px;//430px;
	margin-top:5px !important;
}


.container_table_journaux_modal,
.container_table_pc_modal {
	font-size:1.1em;
	width:520px;
    margin:auto;
}


.container_51_rapp_bq,
.container_50_exercice,
.container_49_ecriture_rechercher,
.container_48_gd_livre,
.container_47_balance,
.container_46_lettrage,
.container_45_journaux_afficher,
.container_44_rapp_bancaire,
.container_43_avs_details_marque_types_saison,
.container_42_avs_details_types_marque_saison,
.container_41_avs_par_saison_marque,
.container_40_ventes_par_saison_marque,
.container_39_panier_marge,
.container_38_ventes_analyse,
.container_37_inventaire,
.container_2_ventes_jour,
.container_3_ventes_mois,
.container_4_ventes_annee,
.container_5_frequentation,
.container_6_pc,
.container_7_journaux,
.container_8_gs,
.container_8_gs_cat,
.container_9_param,
.container_10_tva,
.container_11_num_ecrit_type,
.container_12_jours_feries,
.container_13_users,
.container_14_frais,
.container_15_fournisseur,
.container_17_tresorerie,
.container_18_article,
.container_19_saison,
.container_20_categorie_article,
.container_21_type_article,
.container_22_grille_taille,
.container_23_taille,
.container_24_clients,
.container_25_article_sans_num_cde,
.container_53_articles_avec_num_retour,
.container_52_articles_avec_num_recept,
.container_29_articles_avec_num_cde,
.container_28_article_sans_num_recept,
.container_32_creances,
.container_36_depots_banque,
.container_37_ecrit_a_valider,
.container_35_avoirs,
.container_33_details_client,
.container_34_op_comm,
.container_31_recherche,
.container_30_retours,
.container_27_receptions,
.container_26_commandes,
.container_16_categorie_fournisseur {
	font-size:1.1em;
	margin:auto;
	border:2px solid @bleu_1;
	background-color:@white;
	/*border:1px dashed @vert_fluo;*/
}


.titre_rapp_bq,
.titre_exercice,
.titre_ecriture_rechercher,
.titre_gd_livre,
.titre_balance,
.titre_lettrage,
.titre_journaux_afficher,
.titre_rapp_bancaire,
.titre_ventes_par_marque_saison,
.titre_avs_details_marque_types_saison,
.titre_avs_details_types_marque_saison,
.titre_avs_par_saison_marque,
.titre_ventes_par_saison_marque,
.titre_panier_marge,
.titre_ventes_analyse,
.titre_inventaire,
.titre_depots_banque,
.titre_ecrit_a_valider,
.titre_op_comm,
.titre_details_client,
.titre_avoirs,
.titre_creances,
.titre_recherche,
.titre_retours,
.titre_receptions,
.titre_commandes,
.titre_taille,
.titre_grille_taille,
.titre_type_article,
.titre_categorie_article,
.titre_saison,
.titre_article,
.titre_articles_avec_num_retour,
.titre_articles_avec_num_recept,
.titre_article_avec_num_cde,
.titre_article_sans_num_cde,
.titre_clients,
.titre_tresorerie,
.titre_frais,
.titre_fournisseur,
.titre_categorie_fournisseur,
.titre_jours_feries,
.titre_num_ecrit_type,
.titre_ventes_jour,
.titre_ventes_mois,
.titre_ventes_annees,
.titre_frequentation,
.titre_pc,
.titre_gs,
.titre_gs_cat,
.titre_journaux,
.titre_tva,
.titre_images,
.titre_param,
.titre_users {
	color:@bleu_fonce_1;
	text-align:center;
}

.titre_saison h4,
.titre_categorie_article h4,
.titre_type_article h4,
.titre_grille_taille h4,
.titre_taille h4,
.titre_num_ecrit_type h4,
.titre_tva h4,
.titre_jours_feries h4{
    width:450px;
}
.titre_categorie_fournisseur h4 {
    width:500px;
}
.titre_frais h4,
.titre_frais h5 {
    width:1050px;
}
.titre_images h5 {
    width:978px;
	display: inline-block;
} 
.titre_ecriture_rechercher h5, .titre_ecriture_rechercher h4,
.titre_gd_livre h5, .titre_gd_livre h4,
.titre_balance h5, .titre_balance h4,
.titre_lettrage h5, .titre_lettrage h4,
.titre_journaux_afficher h5,
.titre_rapp_bancaire h5,.titre_rapp_bancaire h4,
.titre_article_sans_num_cde h4,.titre_article_sans_num_cde h5,
.titre_op_comm h5,
.titre_article h5 {
    width:588px;
	display: inline-block;
}
.titre_pc h4 {
    width:600px;
}
.titre_journaux h4 {
    width:800px;
}
.titre_fournisseur h4,
.titre_clients h4 {
    width:850px;
}
.titre_retours h4,
.titre_receptions h4,
.titre_commandes h4,
.titre_articles_avec_num_retour h5, .titre_articles_avec_num_retour h4,
.titre_articles_avec_num_recept h5, .titre_articles_avec_num_recept h4,
.titre_article_avec_num_cde h5, .titre_article_avec_num_cde h4,
.titre_rapp_bq h5, .titre_rapp_bq h4,
.titre_exercice h5, .titre_exercice h4,
.titre_tresorerie h4,
.titre_ventes_par_marque_saison h4,
.titre_avs_details_marque_types_saison h4,
.titre_avs_details_types_marque_saison h4,
.titre_avs_par_saison_marque h4,
.titre_ventes_par_saison_marque h4,
.titre_panier_marge h4,
.titre_ventes_analyse h5 {
    width:950px;
}


.container_table_rapp_bq,
.container_table_exercice,
.container_table_ecriture_rechercher,
.container_table_gd_livre,
.container_table_balance,
.container_table_lettrage,
.container_table_journaux_afficher,
.container_table_rapp_bancaire,
.container_table_ventes_par_marque_saison,
.container_table_avs_details_marque_types_saison,
.container_table_avs_details_types_marque_saison,
.container_table_avs_par_saison_marque,
.container_table_ventes_par_saison_marque,
.container_table_panier_marge,
.container_table_ventes_analyse,
.container_table_inventaire,
.container_table_op_comm,
.container_table_details_client,
.container_table_depots_banque,
.container_table_ecrit_a_valider,
.container_table_avoirs,
.container_table_creances,
.container_table_recherche,
.container_table_retours,
.container_table_receptions,
.container_table_commandes,
.container_table_taille,
.container_table_grille_taille,
.container_table_type_article,
.container_table_categorie_article,
.container_table_saison,
.container_table_article,
.container_table_articles_avec_num_retour,
.container_table_articles_avec_num_recept,
.container_table_article_avec_num_cde,
.container_table_article_sans_num_cde,
.container_table_clients,
.container_table_client,
.container_table_tresorerie,
.container_table_frais,
.container_table_fournisseur,
.container_table_categorie_fournisseur,
.container_table_jours_feries,
.container_table_num_ecrit_type,
.container_table_ventes_jour,
.container_table_journaux,
.container_table_images,
.container_table_param,
.container_table_tva,
.container_table_pc,
.container_table_gs,
.container_table_gs_cat,
.container_table_users {
	margin:auto;
}
.container_table_ventes_mois,
.container_table_ventes_annees,
.container_table_frequentation {
	margin:15px;
}
.container_table_rapp_bq,
.container_table_exercice,
.container_table_ecriture_rechercher,
.container_table_gd_livre,
.container_table_balance,
.container_table_lettrage,
.container_table_journaux_afficher,
.container_table_rapp_bancaire,
.container_table_ventes_par_marque_saison,
.container_table_avs_details_marque_types_saison,
.container_table_avs_details_types_marque_saison,
.container_table_avs_par_saison_marque,
.container_table_ventes_par_saison_marque,
.container_table_panier_marge,
.container_table_ventes_analyse,
.container_table_inventaire,
.container_table_op_comm,
.container_table_details_client,
.container_table_depots_banque,
.container_table_ecrit_a_valider,
.container_table_avoirs,
.container_table_creances,
.container_table_recherche,
.container_table_retours,
.container_table_receptions,
.container_table_commandes,
.container_table_taille,
.container_table_grille_taille,
.container_table_type_article,
.container_table_categorie_article,
.container_table_saison,
.container_table_client,
.container_table_clients,
.container_table_articles_avec_num_retour,
.container_table_articles_avec_num_recept,
.container_table_article_avec_num_cde,
.container_table_article_sans_num_cde,
.container_table_article,
.container_table_tresorerie,
.container_table_frais,
.container_table_fournisseur,
.container_table_categorie_fournisseur,
.container_table_jours_feries,
.container_table_num_ecrit_type,
.container_table_tva,
.container_table_ventes_jour,
.container_table_ventes_mois,
.container_table_ventes_annees,
.container_table_frequentation,
.container_table_journaux,
.container_table_images,
.container_table_param,
.container_table_pc,
.container_table_gs,
.container_table_gs_cat,
.container_table_users {
	margin-bottom:5px;
	padding:5px;
	border:1px solid @bleu_1;
	background-color: @bleu_clair_3;
	/*border:1px dashed @vert_fluo;*/
}
#table_article_sans_num_cde,
#table_clients,
#table_fournisseur {
	width:860px;
	td {
		padding:0px 3px;
	}
}
#container_25_article_sans_num_cde {
	 #table_article td:nth-child(19),
	 #table_article td:nth-child(20) {
	 	width:35px;
	 }
}
#container_53_articles_avec_num_retour,
#container_52_articles_avec_num_recept,
#container_29_articles_avec_num_cde {
	 #table_article td:nth-child(16) {
	 	width:35px;
	 }
}
#container_18_article {	//liste articles d'un retour
	 #table_article td:nth-child(17) {
	 	width:35px;
	 }
}
//#table_cb_depot td:nth-child(1),
//#table_ch_depot td:nth-child(1),
//#table_esp_depot td:nth-child(1),

#table_cb_a_deposer td:nth-child(1),
#table_ch_a_deposer td:nth-child(1),
#table_esp_a_deposer td:nth-child(1),
[id^="table_ecrit_a_valider_"] td:nth-child(1),
//#table_depots_banque td:nth-child(6),
#table_avoirs td:nth-child(9),
#table_creances td:nth-child(9),
#table_retours td:nth-child(7), #table_retours td:nth-child(8),  #table_retours td:nth-child(9),
#table_receptions td:nth-child(8), #table_receptions td:nth-child(9),  #table_receptions td:nth-child(10),
#table_commandes td:nth-child(8), #table_commandes td:nth-child(9),  #table_commandes td:nth-child(10),
#table_taille td:nth-child(5), #table_taille td:nth-child(6), 
#table_grille_taille td:nth-child(4), #table_grille_taille td:nth-child(5), 
#table_type_article td:nth-child(3), #table_type_article td:nth-child(4), 
#table_categorie_article td:nth-child(2), #table_categorie_article td:nth-child(3), 
#table_saison td:nth-child(3), #table_saison td:nth-child(4), 
#table_clients td:nth-child(10), #table_clients td:nth-child(11), 
//#table_client td:nth-child(4), 
#table_article td:nth-child(19), #table_article td:nth-child(20), 
#table_fournisseur td:nth-child(9), #table_fournisseur td:nth-child(10), 
#table_categorie_fournisseur td:nth-child(3), #table_categorie_fournisseur td:nth-child(4), 
#table_jours_feries td:nth-child(3), #table_jours_feries td:nth-child(4),
#table_num_ecrit_type td:nth-child(4), #table_num_ecrit_type td:nth-child(5),
#table_journaux td:nth-child(7), #table_journaux td:nth-child(8),
#table_tva td:nth-child(3), #table_tva td:nth-child(4),
#table_pc td:nth-child(3), #table_pc td:nth-child(4), #table_pc td:nth-child(5),
#table_gs td:nth-child(3), #table_gs td:nth-child(4), #table_gs td:nth-child(5),
#table_gs_cat td:nth-child(4), #table_gs_cat td:nth-child(5), #table_gs_cat td:nth-child(6), #table_gs_cat td:nth-child(7) {
	width:35px;
}
#table_frais td:nth-child(5), #table_frais td:nth-child(6) {
	width:20px;
}
#table_ventes_jour th:nth-child(10),
#table_ventes_jour th:nth-child(11),
#table_ventes_jour th:nth-child(12) {
	width:40px;
}
//#table_depot {
table[id^="table_depot_"] {	
	th {
		background-color: @bleu_fonce_4 !important;
		color:#fff !important;
	}
	width:600px;
	border:2px solid @bleu_1;
	td:nth-child(1) {
		width:40% !important;
	}
	td:nth-child(2),
	td:nth-child(3),
	td:nth-child(4)	{
		width:20% !important;
	}
}
#table_cb_depot,
#table_ch_depot,
#table_esp_depot {
	td:nth-child(1),
	td:nth-child(4){
		width:15%;
	}
	td:nth-child(2),
	td:nth-child(3){
		width:35%;
	}
}


#table_ventes_jour th:nth-child(13) {
	width:60px;
}
#table_ventes_jour th:nth-child(1),
#table_ventes_jour th:nth-child(3),
#table_ventes_jour th:nth-child(6) {
	width:35px;
}
#table_ventes_jour th:nth-child(8),
#table_ventes_jour th:nth-child(9) {
	width:25px;
}
#table_ventes_jour th:nth-child(2) {
	width:150px;
}
#table_ventes_jour th:nth-child(7) {
	width:100px;
}



#table_creances td:nth-child(8) {
	width:70px;
}

#table_details_client td:nth-child(4),
#table_details_client td:nth-child(6),
#table_details_client td:nth-child(8) {
	width:35px;
}
#table_details_client td:nth-child(1),
#table_details_client td:nth-child(5),
#table_details_client td:nth-child(7),
#table_details_client td:nth-child(9),
#table_details_client td:nth-child(10),
#table_details_client td:nth-child(11),
#table_details_client td:nth-child(12) {
	width:60px;
}
#table_details_client td:nth-child(7) {
	width:80px;
}
#table_details_client td:nth-child(2),
#table_details_client td:nth-child(3) {
	width:150px;
}
	
#table_images td:nth-child(12),
#table_images td:nth-child(13),
#table_images td:nth-child(14) {
	width:40px;
}

#table_param td:nth-child(1),
#table_param td:nth-child(2),
#table_param td:nth-child(15) {
	width:40px;
}
#table_param td:nth-child(3),
#table_param td:nth-child(4),
#table_param td:nth-child(5),
#table_param td:nth-child(6),
#table_param td:nth-child(7) {
	width:30px;
}
#table_param td:nth-child(8),
#table_param td:nth-child(9),
#table_param td:nth-child(10),
#table_param td:nth-child(11),
#table_param td:nth-child(12),
#table_param td:nth-child(13),
#table_param td:nth-child(14) {
	width:20px;
}

#table_journaux td:nth-child(2),
#table_journaux td:nth-child(3),
#table_journaux td:nth-child(4),
#table_journaux td:nth-child(5),
#table_journaux td:nth-child(6) {
	width:95px;
}
#table_users td:nth-child(1) {
	width:30px;
}
#table_users td:nth-child(2),
#table_users td:nth-child(4),
#table_users td:nth-child(5),
#table_users td:nth-child(6),
#table_users td:nth-child(7),
#table_users td:nth-child(8) {
	width:150px;
}
#table_users td:nth-child(3),
#table_users td:nth-child(10) {
	width:50px;
}
#table_users td:nth-child(9) {
	width:70px;
}
#table_users td:nth-child(11) {
	width:60px;
}
#table_users td:nth-child(12) {
	width:50px;
}
#table_pc td:nth-child(1) {
	text-align:left;
	text-indent:10px;
}
[id^="table_ventes_analyse_"] a,
#table_rapp_bq a,
#table_exercice a,
#table_ecriture_rechercher a,
#table_gd_livre a,
#table_balance a,
#table_lettrage a,
#table_rapp_bancaire a,
#table_journaux_afficher a,
#table_inventaire a,
#table_op_comm a,
#table_details_client a,
#table_cb_depot a,
#table_ch_depot a,
#table_esp_depot a,
#table_cb_a_deposer a,
#table_ch_a_deposer a,
#table_esp_a_deposer a,
[id^="table_ecrit_a_valider_"] a,
//#table_depots_banque a,
#table_avoirs a,
#table_creances a,
#table_retours a,
#table_receptions a,
#table_commandes a,
#table_taille a,
#table_grille_taille a,
#table_type_article a,
#table_categorie_article a,
#table_saison a,
#table_client a,
#table_clients a,
#table_articles_avec_num_retour a,
#table_articles_avec_num_recept a,
#table_article_avec_num_cde a,
#table_article_sans_num_cde a,
#table_article a,
#table_frais a,
#table_frais_recap_annuel a,
#table_fournisseur a,
#table_categorie_fournisseur a,
#table_jours_feries a,
#table_num_ecrit_type a,
#table_ventes_jour a,
#table_journaux a,
#table_images a,
#table_param a,
#table_tva a,
#table_pc a,
#table_gs a,
#table_gs_cat a,
#table_users a {
	font-size:0.9em;
} 
[id^="table_avs_details_marque_types_saison_"],
[id^="table_avs_details_types_marque_saison_"],
[id^="table_avs_par_saison_marque_"],
[id^="table_ventes_par_saison_marque_"],
[id^="table_panier_marge_"] {
	width:239px !important;
}
//#table_panier_marge,
[id^="table_avs_details_marque_types_saison_"],
[id^="table_avs_details_types_marque_saison_"],
[id^="table_avs_par_saison_marque_"],
[id^="table_ventes_par_saison_marque_"],
[id^="table_panier_marge_"],
[id^="table_ventes_analyse_"],
#table_rapp_bq,
#table_exercice,
#table_ecriture_rechercher,
#table_gd_livre,
#table_balance,
#table_lettrage,
#table_journaux_afficher,
#table_rapp_bancaire,
#table_ventes_par_marque_saison,
#table_inventaire,
#table_op_comm,
#table_details_client,
#table_cb_depot,
#table_ch_depot,
#table_esp_depot,
#table_cb_a_deposer,
#table_ch_a_deposer,
#table_esp_a_deposer,
[id^="table_ecrit_a_valider_"],
//#table_depots_banque,
#table_avoirs,
#table_creances,
#table_retours,
#table_receptions,
#table_commandes,
#table_taille,
#table_grille_taille,
#table_type_article,
#table_categorie_article,
#table_saison,
#table_articles_avec_num_retour,
#table_articles_avec_num_recept,
#table_article_avec_num_cde,
#table_article,
#table_tresorerie,
#table_frais,
#table_frais_recap_annuel,
#table_fournisseur,
#table_client,
#table_categorie_fournisseur,
#table_jours_feries,
#table_num_ecrit_type,
#table_ventes_jour,
#table_ventes_mois,
#table_ventes_annees,
#table_journaux,
#table_images,
#table_param,
#table_tva,
#table_pc,
#table_gs,
#table_gs_cat,
#table_frequentation,
#table_users {
	width:100%;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	margin:auto;
	text-align:center;
	/**/
}

[id^="table_ventes_analyse_"] {
	cursor:pointer;
	tr.hidable {
		display:none;
	}
}
span.hidable {
	display:none;
}
span.showable {
	display:block;
}
#hide_show_nb_euros {
	cursor:pointer;
	font-style:italic;
	:hover {
		text-decoration: underline;
	}
}

/*#table_details_client {
	border:2px solid @bleu_clair_7;
}*/
[id^="table_avs_details_marque_types_saison_"] th,
[id^="table_avs_details_types_marque_saison_"] th,
[id^="table_avs_par_saison_marque_"] th,
[id^="table_ventes_par_saison_marque_"] th,
[id^="table_panier_marge_"] th,
[id^="table_ventes_analyse_"] th,
#table_rapp_bq th,
#table_exercice th,
#table_ecriture_rechercher th,
#table_gd_livre th,
#table_balance th,
#table_lettrage th,
#table_journaux_afficher th,
#table_rapp_bancaire th,
#table_ventes_par_marque_saison th,
#table_inventaire th,
#table_op_comm th,
#table_details_client th,
#table_cb_depot th,
#table_ch_depot th,
#table_esp_depot th,
#table_cb_a_deposer th,
#table_ch_a_deposer th,
#table_esp_a_deposer th,
[id^="table_ecrit_a_valider_"] th,
//#table_depots_banque th,
#table_avoirs th,
#table_creances th,
#table_retours th,
#table_receptions th,
#table_commandes th,
#table_taille th,
#table_grille_taille th,
#table_type_article th,
#table_categorie_article th,
#table_saison th,
#table_client th,
#table_clients th,
#table_articles_avec_num_retour th,
#table_articles_avec_num_recept th,
#table_article_avec_num_cde th,
#table_article_sans_num_cde th,
#table_article th,
#table_tresorerie th,
#table_frais th,
#table_frais_recap_annuel th,
#table_fournisseur th,
#table_categorie_fournisseur th,
#table_jours_feries th,
#table_num_ecrit_type th,
#table_ventes_jour th, 
#table_ventes_mois th,
#table_ventes_annees th,
#table_frequentation th,
#table_journaux th,
#table_tva th,
#table_pc th,
#table_gs th,
#table_gs_cat th,
#table_users th {
    color:@gris_fonce;
	border:1px solid @bleu_clair_1;
	text-align:center;
	background-color: @bleu_clair_1;
	font-weight:bold;
}

#table_param th,
#table_images th {
    color:@gris_fonce;
	border:1px solid @bleu_clair_1;
	text-align:center;
	background-color: @bleu_clair_1;
}
#table_param th.thead_super,
#table_images th.thead_super {
	background-color: @bleu_fonce_1;
	color:@white;
}
[id^="table_avs_details_marque_types_saison_"] td,
[id^="table_avs_details_types_marque_saison_"] td,
[id^="table_avs_par_saison_marque_"] td,
[id^="table_ventes_par_saison_marque_"] td,
[id^="table_panier_marge_"] td,
[id^="table_ventes_analyse_"] td,
#table_rapp_bq td,
#table_exercice td,
#table_ecriture_rechercher td,
#table_gd_livre td,
#table_balance td,
#table_lettrage td,
#table_journaux_afficher td,
#table_rapp_bancaire td,
#table_ventes_par_marque_saison td,
#table_inventaire td,
#table_op_comm td,
#table_details_client td,
#table_cb_depot td,
#table_ch_depot td,
#table_esp_depot td,
#table_cb_a_deposer td,
#table_ch_a_deposer td,
#table_esp_a_deposer td,
[id^="table_ecrit_a_valider_"] td,
//#table_depots_banque td,
#table_avoirs td,
#table_creances td,
#table_retours td,
#table_receptions td,
#table_commandes td,
#table_taille td,
#table_grille_taille td,
#table_type_article td,
#table_categorie_article td,
#table_saison td,
#table_clients td,
#table_articles_avec_num_retour td,
#table_articles_avec_num_recept td,
#table_article_avec_num_cde td,
#table_article_sans_num_cde td,
#table_article td,
#table_tresorerie td,
#table_frais td,
#table_frais_recap_annuel td,
#table_fournisseur td,
#table_categorie_fournisseur td,
#table_jours_feries td,
#table_num_ecrit_type td,
#table_ventes_jour td, 
#table_ventes_mois td,
#table_ventes_annees td,
#table_frequentation td,
#table_journaux td,
#table_images td,
#table_param td,
#table_tva td,
#table_pc td,
#table_gs td,
#table_gs_cat td,
#table_users td {
	border:1px solid @bleu_clair_2;  //#DAE2F1;
	text-align:center;
}
#table_client td {
	border:1px solid @bleu_clair_2;  //#DAE2F1;
	text-align:left;
}
#table_ventes_mois tr:hover td,
#table_ventes_annees tr:hover td,
#table_frequentation tr:hover td {
	color:@white;
	background-color:@bleu_fonce_2;
}
.container_table_tresorerie #table_tresorerie tr td.vers_liste_tresorerie_ca_mois:hover,
.container_table_tresorerie #table_tresorerie tr td.vers_liste_tresorerie_rubrique_frais_exercice:hover {
	background-color: @bleu_clair_1;  //#DAE2F1;
	color:@rouge_sombre;
	font-weight:bold;
	cursor:pointer;
}
.container_table_tresorerie #table_tresorerie tr td.vers_liste_tresorerie_rubrique_frais_mois:hover,
#table_ventes_mois tr td.vers_liste_ventes_jour:hover,
#table_ventes_annees tr td.vers_liste_ventes_mois:hover,
#table_frequentation tr td.vers_liste_ventes_jour:hover {
	//text-align:left;
	background-color: @bleu_clair_1;  //#DAE2F1;
	color:@rouge_sombre;
	font-weight:bold;
	cursor:pointer;
}
#table_tresorerie tr td.vers_liste_tresorerie_rubrique_frais_mois {
	width:60px;
}
[id^="table_avs_details_marque_types_saison_"] tr.tr_surligne:hover,
[id^="table_avs_details_types_marque_saison_"] tr.tr_surligne:hover,
[id^="table_avs_par_saison_marque_"] tr.tr_surligne:hover,
[id^="table_ventes_par_saison_marque_"] tr.tr_surligne:hover,
[id^="table_panier_marge_"] tr.tr_surligne:hover {
	cursor:pointer;
}

#table_exercice tr.tr_surligne:hover td,
#table_ecriture_rechercher tr.tr_surligne:hover td,
#table_gd_livre tr.tr_surligne:hover td,
#table_balance tr.tr_surligne:hover td,
#table_lettrage tr.tr_surligne:hover td,
#table_journaux_afficher tr.tr_surligne:hover td,
#table_rapp_bancaire tr.tr_surligne:hover td,
#table_ventes_par_marque_saison tr.tr_surligne:hover td,
#table_inventaire tr.tr_surligne:hover td,
#table_cb_depot tr.tr_surligne:hover td,
#table_ch_depot tr.tr_surligne:hover td,
#table_esp_depot tr.tr_surligne:hover td,
#table_cb_a_deposer tr.tr_surligne:hover td,
#table_ch_a_deposer tr.tr_surligne:hover td,
#table_esp_a_deposer tr.tr_surligne:hover td,
[id^="table_ecrit_a_valider_"] tr.tr_surligne:hover td,
//#table_depots_banque tr.tr_surligne:hover td,
#table_avoirs tr.tr_surligne:hover td,
#table_creances tr.tr_surligne:hover td,
//#table_retours tr.tr_surligne:hover td,
//#table_receptions tr.tr_surligne:hover td,
//#table_commandes tr.tr_surligne:hover td,
#table_taille tr.tr_surligne:hover td,
#table_grille_taille tr.tr_surligne:hover td,
#table_type_article tr.tr_surligne:hover td,
#table_categorie_article tr.tr_surligne:hover td,
#table_saison tr.tr_surligne:hover td,
//#table_article tr.tr_surligne:hover td,
#table_articles_avec_num_retour tr.tr_surligne:hover td,
#table_articles_avec_num_recept tr.tr_surligne:hover td,
#table_article_avec_num_cde tr.tr_surligne:hover td,
#table_article_sans_num_cde tr.tr_surligne:hover td,
#table_clients tr.tr_surligne:hover td,
#table_tresorerie tr.tr_surligne:hover td,
#table_frais tr.tr_surligne:hover td,
#table_frais_recap_annuel tr.tr_surligne:hover td,
#table_fournisseur tr.tr_surligne:hover td,
#table_categorie_fournisseur tr.tr_surligne:hover td,
#table_jours_feries tr.tr_surligne:hover td,
#table_num_ecrit_type tr.tr_surligne:hover td,
#table_tva tr.tr_surligne:hover td,
#table_journaux  tr.tr_surligne:hover td,
#table_gs tr.tr_surligne:hover td,
#table_gs_cat tr.tr_surligne:hover td,
#table_users tr.tr_surligne:hover td {
	//color:@white;
	background-color:@bleu_fonce_2;
	cursor:pointer;
}
#table_pc td.td_surligne:hover {
	background-color:@bleu_fonce_2;
	cursor:pointer;
}
.encadre_annee_en_cours{
	border-top: 2px dotted @rouge_sombre;
	border-bottom: 2px dotted @rouge_sombre;
}
[id^="table_avs_details_marque_types_saison_"] tr:nth-child(odd),
[id^="table_avs_details_types_marque_saison_"] tr:nth-child(odd),
[id^="table_avs_par_saison_marque_"] tr:nth-child(odd),
[id^="table_ventes_par_saison_marque_"] tr:nth-child(odd),
[id^="table_panier_marge_"] tr:nth-child(odd),
[id^="table_ventes_analyse_"] tr:nth-child(odd),
#table_rapp_bq tr:nth-child(odd),
#table_exercice tr:nth-child(odd),
#table_ecriture_rechercher tr:nth-child(odd),
#table_gd_livre tr:nth-child(odd),
#table_balance tr:nth-child(odd),
#table_lettrage tr:nth-child(odd),
#table_journaux_afficher tr:nth-child(odd),
#table_rapp_bancaire tr:nth-child(odd),
#table_ventes_par_marque_saison tr:nth-child(odd),
#table_inventaire tr:nth-child(odd),
#table_op_comm tr:nth-child(odd),
#table_details_client tr:nth-child(odd),
#table_cb_depot tr:nth-child(odd),
#table_ch_depot tr:nth-child(odd),
#table_esp_depot tr:nth-child(odd),
#table_cb_a_deposer tr:nth-child(odd),
#table_ch_a_deposer tr:nth-child(odd),
#table_esp_a_deposer tr:nth-child(odd),
[id^="table_ecrit_a_valider_"] tr:nth-child(odd),
//#table_depots_banque tr:nth-child(odd),
#table_avoirs tr:nth-child(odd),
#table_creances tr:nth-child(odd),
#table_retours tr:nth-child(odd),
#table_receptions tr:nth-child(odd),
#table_commandes tr:nth-child(odd),
#table_taille tr:nth-child(odd),
#table_grille_taille tr:nth-child(odd),
#table_type_article tr:nth-child(odd),
#table_categorie_article tr:nth-child(odd),
#table_saison tr:nth-child(odd),
#table_clients tr:nth-child(odd),
#table_articles_avec_num_retour tr:nth-child(odd),
#table_articles_avec_num_recept tr:nth-child(odd),
#table_article_avec_num_cde tr:nth-child(odd),
#table_article_sans_num_cde tr:nth-child(odd),
#table_article tr:nth-child(odd),
#table_num_ecrit_type tr:nth-child(odd),
#table_tresorerie tr:nth-child(odd),
#table_frais tr:nth-child(odd),
#table_frais_recap_annuel tr:nth-child(odd),
#table_fournisseur tr:nth-child(odd),
#table_categorie_fournisseur tr:nth-child(odd),
#table_jours_feries tr:nth-child(odd),
#table_ventes_jour tr:nth-child(odd),
#table_ventes_annees tr:nth-child(odd),
#table_journaux tr:nth-child(odd),
#table_images tr:nth-child(odd),
#table_param tr:nth-child(odd),
#table_tva tr:nth-child(odd),
#table_pc tr:nth-child(odd),
#table_gs tr:nth-child(odd),
#table_gs_cat tr:nth-child(odd),
#table_users tr:nth-child(odd) {
    background: @bleu_clair_2;
}
[id^="table_avs_details_marque_types_saison_"] tr:nth-child(even),
[id^="table_avs_details_types_marque_saison_"] tr:nth-child(even),
[id^="table_avs_par_saison_marque_"] tr:nth-child(even),
[id^="table_ventes_par_saison_marque_"] tr:nth-child(even),
[id^="table_panier_marge_"] tr:nth-child(even),
[id^="table_ventes_analyse_"] tr:nth-child(even),
#table_rapp_bq tr:nth-child(even),
#table_exercice tr:nth-child(even),
#table_ecriture_rechercher tr:nth-child(even),
#table_gd_livre tr:nth-child(even),
#table_balance tr:nth-child(even),
#table_lettrage tr:nth-child(even),
#table_journaux_afficher tr:nth-child(even),
#table_rapp_bancaire tr:nth-child(even),
#table_ventes_par_marque_saison tr:nth-child(even),
#table_inventaire tr:nth-child(even),
#table_op_comm tr:nth-child(even),
#table_details_client tr:nth-child(even),
#table_cb_depot tr:nth-child(even),
#table_ch_depot tr:nth-child(even),
#table_esp_depot tr:nth-child(even),
#table_cb_a_deposer tr:nth-child(even),
#table_ch_a_deposer tr:nth-child(even),
#table_esp_a_deposer tr:nth-child(even),
[id^="table_ecrit_a_valider_"] tr:nth-child(even),
//#table_depots_banque tr:nth-child(even),
#table_avoirs tr:nth-child(even),
#table_creances tr:nth-child(even),
#table_retours tr:nth-child(even),
#table_receptions tr:nth-child(even),
#table_commandes tr:nth-child(even),
#table_taille tr:nth-child(even),
#table_grille_taille tr:nth-child(even),
#table_type_article tr:nth-child(even),
#table_categorie_article tr:nth-child(even),
#table_saison tr:nth-child(even),
#table_clients tr:nth-child(even),
#table_articles_avec_num_retour tr:nth-child(even),
#table_articles_avec_num_recept tr:nth-child(even),
#table_article_avec_num_cde tr:nth-child(even),
#table_article_sans_num_cde tr:nth-child(even),
#table_article tr:nth-child(even),
#table_num_ecrit_type tr:nth-child(even),
#table_tresorerie tr:nth-child(even),
#table_frais tr:nth-child(even),
#table_frais_recap_annuel tr:nth-child(even),
#table_fournisseur tr:nth-child(even),
#table_categorie_fournisseur tr:nth-child(even),
#table_jours_feries tr:nth-child(even),
#table_ventes_jour tr:nth-child(even),
#table_ventes_annees tr:nth-child(even),
#table_journaux tr:nth-child(even),
#table_images tr:nth-child(even),
#table_param tr:nth-child(even),
#table_tva tr:nth-child(even),
#table_pc tr:nth-child(even),
#table_gs tr:nth-child(even),
#table_gs_cat tr:nth-child(even),
#table_users tr:nth-child(even) {
    background: @bleu_clair_4;
} 
#table_ventes_mois .td_date_dans_mois,
#table_frequentation .td_date_dans_mois {
	width:20px;
	font-weight:bold;
	background-color:@bleu_clair_1;
}
#table_ventes_mois .td_border_right,
#table_ventes_annees .td_border_right,
#table_frequentation .td_border_right {
	border-right: 2px solid @bleu_clair_1;
}
.jour_ferie {
	background-color : @rose;
}
.jour_non_ouvre {
	 background-color : @bleu_clair_2;
}
.bg_fonce {
	background-color:@bleu_clair_1;
}
.chiffre_negatif {
    color:@rouge;
    font-weight:bold;
}
.ligne_separation {
    background-color:@bleu_fonce_2;
}
th {	//, td
	//width:1px;
	white-space:nowrap;
}
/*td:nth-last-child(1),td:nth-last-child(2),td:nth-last-child(3),td:nth-last-child(4),  {
    width:35px;
}*/
.table_article_crrv {
	//width:100%;	//500px;
	margin-top:10px;
	caption {
		font-weight:bold;
		font-size:1.1em;
	}
	th {	//, td
		width:90px;
		white-space:nowrap;	
	}
 }
 
 //.vers_details_client_par_type_article,
 #a_vers_rapp_bq_liste,
 #a_commande_a_afficher,
 #a_reception_a_afficher,
 #a_retour_a_afficher,
 .vers_ventes_jour,
 #details_client_par_type_par_marque,
 .vers_details_client,
 .vers_details_article,
 #a_facture_vente_pdf {
	cursor:pointer;
	text-decoration: underline;
}
 
 .type_article_selected {
 	border:4px solid green;
 	//background-color:#88ea9e;
 }
 
/*// LISTES VENTES FREQ JOURNAUX //////////////////////////////////////////////////////////////////////////////*/
#titre_bis_ventes_par_marque_saison_dates {
	//display:inline;
	padding-left:290px;
	margin-bottom:10px;
}
#select_ecriture_rechercher_quelles_ecritures,
#select_ecriture_rechercher_num_compte_start,
#select_ecriture_rechercher_num_compte_end,
#select_gd_livre_start,
#select_gd_livre_end,
#select_lettrage,
#select_rapp_bancaire_quelles_ecritures,
#select_lettrage_quelles_ecritures,
#select_achats_previsionnel_saison_ws,
#select_avs_saison_ws,
#journaux_afficher_input_date_start_datepicker,
#journaux_afficher_input_date_end_datepicker,
#ventes_input_marque_saison_date_start_datepicker,
#ventes_input_marque_saison_date_end_datepicker {
 	display:inline;
	height:22px;
	text-align:center;
	font-size:0.9em;
	font-weight:bold;
	margin-bottom:5px;
}
#select_ecriture_rechercher_quelles_ecritures,
#select_ecriture_rechercher_num_compte_start,
#select_ecriture_rechercher_num_compte_end,
#select_gd_livre_start,
#select_gd_livre_end,
#select_lettrage,
#select_lettrage_quelles_ecritures,
#select_rapp_bancaire_quelles_ecritures,
#select_achats_previsionnel_saison_ws,
#select_avs_saison_ws {
	width:160px;
}
#journaux_afficher_input_date_start_datepicker,
#journaux_afficher_input_date_end_datepicker,

#ventes_input_marque_saison_date_start_datepicker,
#ventes_input_marque_saison_date_end_datepicker {
	width:80px;
}
#ecriture_rechercher_num_piece_input,
#ecriture_rechercher_libelle_input,
#ecriture_rechercher_montant_mini_input,
#ecriture_rechercher_montant_maxi_input,
#ecriture_rechercher_lettrage_input,

#declaration_tva_input_date_start_datepicker,
#declaration_tva_input_date_end_datepicker,
#ecriture_rechercher_date_start_datepicker,
#ecriture_rechercher_date_end_datepicker,

#gd_livre_input_date_start_datepicker,
#gd_livre_input_date_end_datepicker,

#balance_input_date_start_datepicker,
#balance_input_date_end_datepicker,

#fec_input_date_validation_datepicker,

#lettrage_input_date_start_datepicker,
#lettrage_input_date_end_datepicker,
#lettrage_solde_lettrage_input,
#lettrage_code_lettrage_input,

#rapp_bancaire_code_pointage_input,
#rapp_bancaire_input_date_start_datepicker,
#rapp_bancaire_input_date_end_datepicker,
#rapp_bancaire_input_ecart_rapprochement,
#rapp_bancaire_input_solde_fin_de_releve {
 	display:inline;
	height:22px;
	text-align:center;
	width:80px;
	margin-bottom:5px;
}
td {
	padding:0px !important;
	line-height:14px !important;
}

// SUPP/MODIF ECRITURE
.tr_ligne_a_modifier {
	.cadre_vert;
	//border:2px solid green;
}
.line_height_35 {
	line-height:35px !important;
}
 #ecrit_modif_num_cpte_input,
 #ecrit_modif_num_ecrit_input {
 	width:70px;
 	text-align:center;
 	color:green;
 }
 #ecrit_modif_debit_input,
 #ecrit_modif_credit_input {
 	width:70px;
 	text-align:right;
 	color:green;
 }
 #ecrit_modif_libelle_input {
 	width:200px;
 	text-align:center;
 	color:green;
 }
 .tr_titre_ligne_a_modifier {
 	.cadre_vert;
 	//border:2px solid green;
 	font-size:1.4em;
 	color:green;
 }
 .cadre_vert {
	border:2px solid green;
}
#a_ecrit_a_modifier_annuler_tout,
#a_ecrit_a_modifier_modifier {
	cursor:pointer;	
}

// SUPP/MODIF ECRITURE


.table_frais_tr_hidable,
.table_an_frais_tr_hidable {
	display:none;
}
[id^="titre_table_frais_"],
[id^="titre_table_an_frais_"] {
	cursor:pointer;
}
