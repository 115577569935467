/*// PARAM //////////////////////////////////////////////////////////////////////////////*/
//.form_param_label_num_tous_journaux,
.form_param_label_theme_couleur,
.form_param_label_raz_fin_de_mois {
   display: inline-block;
	vertical-align:top;
	width:250px;
	margin-right:25px;
	margin-left:25px;
}
.form_param_label_solde_initial,
.form_param_label_date_solde_initial {
   display: inline-block;
	vertical-align:top;
	width:290px;
	margin-right:5px;
	margin-left:5px;
}

.form_param_label_nb_lignes_maxi_modal,
.form_param_label_nb_ans_maxi_freq,
.form_param_label_nb_ans_maxi_histor,
.form_param_label_nb_ans_maxi_tresor,
.form_param_label_nb_ans_maxi_ca_mois,
.form_param_label_nb_ans_maxi_frais,
.form_param_label_gestion_clients,
.form_param_label_gestion_articles,
.form_param_label_option_compta,
.form_param_label_lundi,
.form_param_label_mardi,
.form_param_label_mercredi,
.form_param_label_jeudi,
.form_param_label_vendredi,
.form_param_label_samedi,
.form_param_label_dimanche {
	vertical-align:top;
	width:270px;
	margin-right:25px;
	margin-left:25px;
}

.fieldset_4 .form_taux_tva_label_id_taux span {
	width:140px;	
}
.fieldset_4 .form_taux_tva_label_id_taux,
.form_param_label_mois_debut_exercice {
	vertical-align:top;
	width:290px;
	//margin-right:25px;
	//margin-left:25px;
}
.form_param_creer_div1 label.form_param_label_mois_debut_exercice span {
	width:140px;
}
.form_param_label_nb_lignes_maxi_modal span {
	vertical-align:bottom;
}
/*.form_param_label_theme_couleur {
	vertical-align:top;
	width:250px;
	margin-right:25px;
	margin-left:25px;
}*/
//.form_param_input_num_tous_journaux
.form_param_input_raz_fin_de_mois {
   display: inline-block;
   margin: 0 auto;
    height: 15px;
    width: 15px;
    font-size: 1.1em;
  text-align: center;
}
.form_param_input_solde_initial,
.form_param_input_date_solde_initial {
   display: inline-block;
   margin: 0 auto;
    height:@input_height_2;
    width: 80px;
    font-size: 1.1em;
  text-align: center;
}
.form_param_input_nb_lignes_maxi_modal,
.form_param_input_nb_ans_maxi_freq,
.form_param_input_nb_ans_maxi_histor,
.form_param_input_nb_ans_maxi_tresor,
.form_param_input_nb_ans_maxi_ca_mois,
.form_param_input_nb_ans_maxi_frais {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  width: 60px;
  text-align: center;
}
.fieldset_4 .form_taux_tva_input_id_taux,
.form_param_input_mois_debut_exercice {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  width: 100px;
  text-align: center;
}
.form_param_input_gestion_clients,
.form_param_input_gestion_articles,
.form_param_input_option_compta,
.form_param_input_lundi,
.form_param_input_mardi,
.form_param_input_mercredi,
.form_param_input_jeudi,
.form_param_input_vendredi,
.form_param_input_samedi,
.form_param_input_dimanche {
  display: inline-block;
  margin: 0 auto;
  height: 15px;
  width: 15px;
  font-size: 1.1em;
  text-align: center;
}
.form_param_select_theme_couleur {
  display: inline-block;
  margin-left : 90px;
  height:@input_height_2;
  font-size: 1.1em;
  width: 120px;
  text-align: center;
}
.fieldset_perso {
	display: block;
	/*margin-left: 2px;
	margin-right: 2px;*/
	//margin: 10px;
	margin-top:5px;//10px;
	margin-bottom:5px;//10px;
	margin-right: 10px;
	margin-left: 10px;
	//padding: 10px;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid #7f7f7f;
}
.fieldset_perso_collection {
	display: block;
	margin: 10px;
	padding: 10px;
	border: 1px solid #7f7f7f;
}
.legend_perso {
    border-width: 0;
    display: inline-block;
    font-size: 1em;
    font-weight: normal;
    margin-bottom: 0;
    margin-left: 30px;
	padding-left:5px;
    width: 170px;
    color :#7f7f7f;
}
.fieldset_1 {
    width:340px;
    float:left;
    height:195px;//230px;
}
.fieldset_2 {
    width:340px;
    float:left;
    height:55px;//70px;
}
.fieldset_3 {
    width:310px;
    float:left;
    height:70px;
}
.fieldset_4 {
    width:340px;
    float:left;
    height:80px;//90px;
}
.fieldset_5 {
    width:700px;
    float:left;
    height:55px;//70px;
}
.fieldset_6 {
    width:700px;
    float:left;
    height:115px;
}

.form_logo_lister_label {
    display: inline-block;
	vertical-align:top;
	width:550px;
	margin-right:5px;
	margin-left:50px;
}
.form_logo_lister_select {
	  display: inline-block;
	  margin-left : 70px;
	  height:@input_height_2;
	  font-size: 1em;
	  width: 270px;
	  text-align: center;
}
.form_logo_select_logo_largeur {
	  display: inline-block;
	  margin-left : 70px;
	  height:@input_height_2;
	  font-size: 1em;
	  width: 90px;
	  text-align: center;
}
.form_image_file {
    display: inline-block;
	vertical-align:top;
	width:400px;
	margin-right:5px;
	margin-left:100px;
}
#image-document-charger-form #image_file,
#image-article-charger-form #image_file {
    display: inline-block;
	vertical-align:top;
	width:250px;
	margin-right:5px;
	//margin-left:100px;
}

/*// PARAM //////////////////////////////////////////////////////////////////////////////*/