/*// RAPP BQ //////////////////////////////////////////////////////////////////////////////*/
.form_rapp_bq_label_solde_bq,
.form_rapp_bq_label_date_rapp,
.form_rapp_bq_label_code_pointage,
.form_rapp_bq_label_appellation {	
	vertical-align:top;
}
//	display:inline-block;
//	vertical-align:top;
//	margin:5px;
//  width: 120px;
//  text-align: center;

	
.form_rapp_bq_label_solde_bq,
.form_rapp_bq_label_date_rapp,
.form_rapp_bq_label_code_pointage,
.form_rapp_bq_label_appellation,
{	
	width:370px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_rapp_bq_input_solde_bq,
.form_rapp_bq_input_code_pointage,
.form_rapp_bq_input_date_rapp {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:80px;
	text-align:center; 
}

.form_rapp_bq_input_appellation {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:200px;
	text-align:center; 
}
//	display:block;
//	margin: 0 auto;
//    line-height: 25px;
//    height:@input_height_2;
//	padding: 3px;
//	text-align:center;
//	width:85px;
/*////////////////////////////////////////////////////////*/

.form_rapp_bq_label_id_appellation {
	width:200px;
	display:inline-block;
	vertical-align:top;
	margin:5px;
	text-align:center; 
}
.form_rapp_bq_input_id_appellation {
	display:inline-block;
	margin: 0 auto 5px;
	height:@input_height_2;
	font-size:1.1em; 
	width:200px;
	text-align:center; 
}
/*// RAPP BQ //////////////////////////////////////////////////////////////////////////////*/




