/*// USER //////////////////////////////////////////////////////////////////////////////*/
.form_user_label_nom,
.form_user_label_prenom,
.form_user_label_adr1,
.form_user_label_adr2,
.form_user_label_cp,
.form_user_label_ville,
.form_user_label_phone1,
.form_user_label_phone2,
.form_user_label_email,
.form_user_label_password,
.form_user_label_password_confirm,
.form_user_label_role,
.form_user_label_is_active,
.form_user_label_nom_entreprise,
.form_user_label_siret,
.form_user_label_identifiant,
.form_user_label_civilite {
	vertical-align:top;
	width:400px;
	margin-right:10px;
	margin-left:10px;
}
.form_user_input_nom,
.form_user_input_prenom,
.form_user_input_adr1,
.form_user_input_adr2,
.form_user_input_cp,
.form_user_input_ville,
.form_user_input_phone1,
.form_user_input_phone2,
.form_user_input_email,
.form_user_input_password,
.form_user_input_password_confirm,
.form_user_input_role,
.form_user_input_is_active,
.form_user_input_nom_entreprise,
.form_user_input_siret,
.form_user_input_identifiant,
.form_user_input_civilite {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:200px;
	text-align:center; 
}
/*// USER //////////////////////////////////////////////////////////////////////////////*/
