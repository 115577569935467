/*// USERDB //////////////////////////////////////////////////////////////////////////////*/
.form_userdb_label_db_user,
.form_userdb_label_db_psswd,
.form_userdb_label_db_name {
	vertical-align:top;
	width:400px;
	margin-right:10px;
	margin-left:10px;
}
.form_userdb_input_db_user,
.form_userdb_input_db_psswd,
.form_userdb_input_db_name {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:200px;
	text-align:center; 
}
/*// USERDB //////////////////////////////////////////////////////////////////////////////*/
