/*// GS //////////////////////////////////////////////////////////////////////////////*/
.gs_enreg_div1 {
	font-size:1.2em;
	width:770px;
	margin:auto;
	border:2px solid @bleu_1;
	background-color:@white;
}

/*.gs_enreg_titre_page > h4 {
	color: @bleu_fonce_1;
	text-align:center;
	font-size:1.4em;
}*/

.form_gs_enreg_titre {
	color: @bleu_fonce_1;/*#22375E;*/
	text-align:center;
	/*border:1px dashed orange;*/
}
.form_gs_enreg_div2 {
	width:750px;
	margin:auto;
	margin-top:20px;
	margin-bottom:20px;
	//font-size:1.2em;    //1.1em;
	background-color:@bleu_clair_3;
	border:1px solid @bleu_1;/*#BFCDE8;*/
}
.form_gs_enreg_div_JDDN {
	/*border:1px dashed #ffffff;*//*orange*/
	margin:10px;
	vertical-align:super;
	text-align:left;
}
.form_gs_enreg_div_NIcLDC {
	/*border:1px dashed #ffffff;*//*DeepPink*/
	margin:10px;
	margin-top:30px;
	margin-bottom:0px;
}

.form_gs_enreg_div_buttons {
	margin-bottom:20px;
	text-align:right;
}
/*.button_enreg_new_ecrit_prog {
	float:left;
	font-style:italic;
	font-size:1em;
}*/
/*.button_add_ligne {
	font-style:italic;
	font-size:0.9em;//1em;
}*/
#fieldset_container {
	text-align:left;
}
/*////////////////////////////////////////////////////////*/
.form_gs_enreg_label_titre,
.form_gs_enreg_label_journal,
.form_gs_enreg_label_date_ecrit,
.form_gs_enreg_label_date_ech,
.form_gs_enreg_label_num_ecriture {
	display:inline-block;
	vertical-align:top;
	margin:5px;
	/*border:1px dashed #ff0000;*//*chocolate*/
	/*text-align:center;*/
}
.form_gs_enreg_label_titre {	
	width:730px;
	text-align:center;
}	
.form_gs_enreg_label_journal {	
	width:100px;	
}	
.form_gs_enreg_label_journal_id {	
	/*display:none;*/	
}	
.form_gs_enreg_label_date_ecrit {	
	width:85px;//120px;
	text-align:center;
	color:#9b9b9b;
}
.form_gs_enreg_label_date_ech {	
	width:95px;//120px;
	text-align:center;
	color:#9b9b9b;
}
.form_gs_enreg_label_num_ecriture {	
	width:110px;//120px;
	text-align:center;
	color:#9b9b9b;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_gs_enreg_input_journal {
	width:60px !important;
	text-align:center;
	display:block;
	margin: 0 auto;
}
.form_gs_enreg_input_journal_id {
	width:60px !important;
	display:block;
	margin: 0 auto;
	text-align:center;
	height:@input_height_2;
}
.form_gs_enreg_input_date_ecrit,
.form_gs_enreg_input_date_ech,
.form_gs_enreg_input_num_ecriture {
	display:block;
	margin: 0 auto;
	text-align:center;
	line-height:25px;
	height:@input_height_2;
}

.form_gs_enreg_input_titre {
	display:inline-block;
	margin: 0 20px;
	text-align:center;
	line-height:25px;
	height:@input_height_2;
}
.form_gs_enreg_input_titre {
	width:400px;
}
.form_gs_enreg_input_date_ecrit,
.form_gs_enreg_input_date_ech,
.form_gs_enreg_input_num_ecriture {
	width:75px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_gs_enreg_div_num_compte,
.form_gs_enreg_div_intitule_compte,
.form_gs_enreg_div_libelle,
.form_gs_enreg_div_debit,
.form_gs_enreg_div_credit {
	display:inline-block;
	font-weight:bold;
	/*border:1px dashed #ffffff;*//*black*/
	text-align:center;
    margin-right :5px;
}
.form_gs_enreg_div_num_compte {
	width:120px;
}
.form_gs_enreg_div_intitule_compte {
	width:220px;
	color:#9b9b9b;
}
.form_gs_enreg_div_libelle {
	width:170px;
}
.form_gs_enreg_div_debit,
.form_gs_enreg_div_credit {
	width:40px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_gs_enreg_label_num_compte,
.form_gs_enreg_label_intitule_compte,
.form_gs_enreg_label_libelle,
.form_gs_enreg_label_debit_credit,
.form_gs_enreg_label_cross {	
	display:inline-block;
	/*border:1px dashed red;*/
	vertical-align:top;
    margin-right :5px;
}
.form_gs_enreg_label_num_compte {	
	width:120px;
}
.form_gs_enreg_label_intitule_compte {	
	width:220px;//230px;
	font-size:0.9em;
}
.form_gs_enreg_label_libelle {	
	width:170px;
}	
.form_gs_enreg_label_debit_credit {	
	width:40px;
	text-align:center; 
	height:30px;
}	
.form_gs_enreg_label_cross {	
	width:40px;
	text-align:center; 
	height:30px;
}/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
.form_gs_enreg_input_num_compte,
.form_gs_enreg_input_intitule_compte,
.form_gs_enreg_input_libelle {
	display:block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
}
.form_gs_enreg_input_num_compte {
	width:70px;
	text-align:center; 
}
.form_gs_enreg_input_intitule_compte {
	width:220px;
	text-align:left;
}
.form_gs_enreg_input_libelle {
	width:170px;
	text-align:left;
}
.form_gs_enreg_input_debit_credit {
	width:15px;
	height:15px;
}
/*////////////////////////////////////////////////////////*/
/*////////////////////////////////////////////////////////*/
/*#form_select_gs_enreg_plan_cpt {
	width:200px;
}*/
.form-control {
  padding: 3px;
}
.form-control[disabled] {
    background-color: @bleu_clair_2;
    cursor: not-allowed;
}
.form-control[readonly] {
    background-color: @bleu_clair_4;
    cursor: not-allowed;
}
.input-group-addon {
    background-color: #ffffff;
    color: #304b7e;/*#c16100;*/
    /*border: 1px solid #ccc;
    border-radius: 4px;
    color: #555;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    padding: 6px 12px;
    text-align: center;*/
}
.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
    border-radius: 3px;
    font-size: 12px;
    height: @input_height_2;//30px;
    line-height: 1.2;//1.5;
    padding: 3px;
}
.cross_supp {
	color:red;
	//margin-left:30px;
	display:inline-block;
	cursor:pointer;
}
#liste_par_categorie_span,
#liste_par_journal_span {
    cursor:pointer;
}
/*// GS //////////////////////////////////////////////////////////////////////////////*/
