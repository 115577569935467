/*// DEPOTS_BANQUE //////////////////////////////////////////////////////////////////////////////*/
/*.input_depot_ref {
	display:inline-block;
	margin:0 auto;
	//margin-left:450px;
	margin-bottom:5px;
	height:@input_height_2;
	font-size:1.3em;
	font-weight:bold;
	text-align:center;
	width:100px;
}*/
[id^="form_div_ecrit_a_valider_"] {
	text-align:left;
	margin-top:10px;
	margin-left:30px;	
}
[id^="form_a_ecrit_a_valider_"] {
	cursor:pointer;
}
[class^="ecrit_a_valider_input_date_"] {
	text-align:center;
	width:70px;
	font-size:1.1em;
	font-weight:bold;
}
[class^="ecrit_a_valider_input_date_"] {
	width:90px;
	height:20px;
}
/*.vers_detail_depot {
	cursor: pointer;
	text-decoration: underline;
}*/
/*#a_depot_mode_paiement_modifier {
	cursor: pointer;
	display:inline-block;

	//text-decoration: underline;
	
}*/
/*// DEPOTS_BANQUE //////////////////////////////////////////////////////////////////////////////*/
