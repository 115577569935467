/*// FRAIS-FOURNISSEUR-CATEGORIE //////////////////////////////////////////////////////////////////////////////*/
.form_frais_label_montant,
.form_frais_label_date,
.form_categorie_fournisseur_label_categorie,
.form_categorie_fournisseur_label_compte,
.form_categorie_fournisseur_label_id_cat {
	vertical-align:top;
	width:400px;
	margin-right:10px;
	margin-left:10px;
}
.form_frais_input_montant,
.form_frais_input_date,
.form_categorie_fournisseur_input_categorie {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:180px;/*70px;*/
	text-align:center; 
}
.form_categorie_fournisseur_input_compte
 {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:120px;/*70px;*/
	text-align:center; 
}
.form_categorie_fournisseur_input_id_cat {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:180px;
	text-align:center; 
}
#form_frais_vers_categorie_fournisseur_creer,
#form_frais_vers_fournisseur_creer {
    .bg_button(@bleu_clair_1);
    margin-left:15px;
    width:30px;
}
#table_journaux_afficher tr th[class^="th_surligne"]:hover,
#table_ecriture_rechercher tr th[class="th_surligne"]:hover,
#table_lettrage tr th[class="th_surligne"]:hover,
#table_rapp_bancaire tr th[class="th_surligne"]:hover,
#table_retours tr th[class="th_surligne"]:hover,
#table_receptions tr th[class="th_surligne"]:hover,
#table_commandes tr th[class="th_surligne"]:hover,
#table_inventaire tr th[class="th_surligne"]:hover,
#table_frais tr th[class="th_surligne"]:hover,
#table_articles_avec_num_retour tr th[class="th_surligne"]:hover,
#table_articles_avec_num_recept tr th[class="th_surligne"]:hover,
#table_article_avec_num_cde tr th[class="th_surligne"]:hover,
#table_article_sans_num_cde tr th[class="th_surligne"]:hover,
#table_article tr th[class="th_surligne"]:hover {
  color: @white;
  background-color: @bleu_fonce_2;
  cursor: pointer;
}
#form_categorie_fournisseur_input_group_compte {
  display: inline-table;
  width: 120px;
}
/*// FRAIS-FOURNISSEUR-CATEGORIE //////////////////////////////////////////////////////////////////////////////*/
