/*// CLIENT //////////////////////////////////////////////////////////////////////////////*/
.form_civilite_label_id_civilite,
.form_client_label_nom,
.form_client_label_prenom,
.form_client_label_mailing,
.form_client_label_npai,
.form_client_label_assimile_local,
.form_client_label_commentaire {
	vertical-align:top;
	width:360px;//400px;
	//margin-right:10px;
	//margin-left:10px;
}
.form_client_input_nom,
.form_client_input_prenom,
.form_client_input_mailing,
.form_client_input_npai,
.form_client_input_assimile_local,
.form_client_input_commentaire {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:160px;
	text-align:center; 
}
.form_civilite_input_id_civilite {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:80px;
	text-align:center; 
}

#form_clients_m_rech_b_client_nom {
	display:inline-block;
	margin-right:30px;
}
/*.form_client_input_civilite {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:90px;
	text-align:center; 
}*/
// SELECTION CLIENTS POUR PDF
/*.form_taux_tva_label_id_taux {
	vertical-align:top;
	width:260px;
	text-align:left;
}*/
.form_clients_selectionner_label_enregister_envoi {
	vertical-align:top;
	width:250px;
}
.form_clients_selectionner_input_enregister_envoi {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:50px;
	text-align:center; 
}
.form_clients_selectionner_label_format,
.form_clients_selectionner_label_ordre,
.form_clients_selectionner_label_logo_largeur,
.form_clients_selectionner_label_zone,
.form_clients_selectionner_label_ca_client,
.form_clients_selectionner_label_date,
.form_clients_selectionner_label_logo {
	vertical-align:top;
	width:340px;
}
.form_clients_selectionner_select_ordre,
.form_clients_selectionner_select_logo_largeur,
.form_clients_selectionner_select_zone,
.form_clients_selectionner_input_ca_client,
.form_clients_selectionner_input_date,
.form_clients_selectionner_input_logo {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:90px;
	text-align:center; 
}
.form_clients_selectionner_select_format {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:140px;
	text-align:center; 
}
/*// CLIENT //////////////////////////////////////////////////////////////////////////////*/
