/*// MARQUE_CATEGORIE_SAISON_TYPE_TVA_TAILLE_GRILLE //////////////////////////////////////////////////////////////////////////////*/
.form_cat_article_label_categorie,
.form_cat_article_label_sous_categorie {
	vertical-align:top;
	width:400px;
}
.form_cat_article_label_id_cat_article {
	vertical-align:top;
	width:280px;/*380px;*/
	text-align:left; 
}
.form_cat_article_input_id_cat_article,
.form_cat_article_input_categorie,
.form_cat_article_input_sous_categorie {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:150px;/*70px;*/
	text-align:center; 
}

 
.form_saison_label_nom_abr,
.form_saison_label_id_nom {
	vertical-align:top;
	width:280px;
	text-align:left; 
}
.form_saison_label_nom {
	vertical-align:top;
	width:400px;
}
.form_saison_input_nom {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:150px;
	text-align:center; 
}
.form_saison_input_nom_abr,
.form_saison_input_id_nom {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:70px;
	text-align:center; 
}

 
.form_type_article_label_nom,
.form_type_article_label_nom_abr {
	vertical-align:top;
	width:400px;
}
.form_type_article_label_id_nom {
	vertical-align:top;
	width:280px;
	text-align:left; 
}
.form_type_article_input_id_nom,
.form_type_article_input_nom,
.form_type_article_input_nom_abr {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:150px;
	text-align:center; 
}

 
.form_taux_tva_label_id_taux {
	vertical-align:top;
	width:260px;
	text-align:left;
}
.form_taux_tva_label_libelle,
.form_taux_tva_label_nom,
.form_taux_tva_label_taux {
	vertical-align:top;
	width:400px;/*380px;*/
}
.form_taux_tva_input_id_taux,
.form_taux_tva_input_libelle,
.form_taux_tva_input_nom,
.form_taux_tva_input_taux {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:90px;
	text-align:center; 
}


.form_taille_label_taille {
	vertical-align:top;
	width:285px;
	text-align:center; 
}
.form_taille_label_id_taille {
	display:inline-block;
	text-align:center; 
	vertical-align:top;
	width:80px;
}
.form_taille_input_id_taille,
.form_taille_input_taille {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:70px;
	text-align:center; 
}

.form_taille_label_cross {
	margin-left:10px;
}

.form_article_div1 .form_grille_taille_label_id_nom {
	text-align:left;
	vertical-align:top;
	width:210px;	//pour form_article
}
.form_taille_creer_div1 .form_grille_taille_label_id_nom {
	text-align:left;
	vertical-align:top;
	width:400px;	//pour form_taille
}


.form_grille_taille_label_id_nom {
	display:inline-block;
	text-align:center; 
	vertical-align:top;
	width:285px;
}
.form_grille_taille_label_cat,
.form_grille_taille_label_sous_cat {
	text-align:left;
	vertical-align:top;
	width:330px;
}
.form_grille_taille_label_nom {
	text-align:left;
	vertical-align:top;
	width:330px;
}
.form_article_div_centre .form_grille_taille_label_nom {
	text-align:center;
	vertical-align:top;
	width:210px;
}
.form_grille_taille_input_nom,
.form_grille_taille_input_id_nom {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:85px;
	text-align:center; 
}
.form_grille_taille_input_cat,
.form_grille_taille_input_sous_cat {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:120px;
	text-align:center; 
}


.form_article_label_modele,
.form_article_label_description,
.form_article_label_prix_achat,
.form_article_label_prix_vente,
.form_article_label_coef,
.form_article_label_couleur,
.form_article_label_ref {
	vertical-align:top;
	width:260px;
	text-align:left; 
}
.form_article_input_ref,
.form_article_input_modele,
.form_article_input_couleur,
.form_article_input_prix_achat,
.form_article_input_prix_vente,
.form_article_input_coef {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:90px;
	text-align:center; 
}
.form_article_input_description {
	display:inline-block;
	margin: 0 auto;
	width:90px;
	//height:5px;
	font-size:1.1em; 
}
.form_article_label_stock {
	display:inline-block;
	text-align:center; 
	vertical-align:top;
	width:50px;
}
.form_article_input_stock {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:40px;
	text-align:center; 
}
.form_article_label_num_reception {
	display:inline-block;
	text-align:center; 
	vertical-align:top;
	width:240px;
}
.form_article_input_num_reception {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:170px;
	text-align:center; 
}
.form_article_label_id_num_reception {
	display:inline-block;
	text-align:center; 
	vertical-align:top;
	width:240px;	
}
.form_article_input_id_num_reception {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:170px;
	text-align:center;	
}
/*.form_article_label_stock {
	display:inline-block;
	text-align:center; 
	vertical-align:top;
	width:50px;
}
.form_article_input_stock {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:40px;
	text-align:center; 
}*/
//créé directement dans table#articles_ventes par gestion_siope.js(articleVentesRechercheAfficher)
.form_article_input_id {	
	display:inline-block;
	height:@input_height_2;
	font-size:1em;//1.1em; 
	font-weight:bold;
	width:50px;
	text-align:center; 
}//créé directement dans gestion_siope.js


.form_article_div_numreception {
	width:260px;
	display: inline-block;
	font-weight: bold;
	text-align: center;
	//padding-left:30px;
}


#button_on_click_add_taille_q_recept {
    background-image: linear-gradient(to bottom, #ffffff 15px, #bdddf0 100%);
    margin-left:16px;
}
#button_on_click_add_taille_a_grille_taille {
    background-image: linear-gradient(to bottom, #ffffff 15px, #bdddf0 100%);
    margin-left:16px;
}
/*.form_article_div1 label.form_grille_taille_label_id > span {
	text-align:left;
	width:105px;
	margin-bottom:30px;
}*/
.form_article_div {
	text-align:left;
}
.form_article_div_taille {
	width:80px;
	display:inline-block;
	font-weight:bold;
	text-align:center;
	margin-left:45px;
}
.form_article_div_quantite {
	width:50px;
	display: inline-block;
	font-weight: bold;
	text-align: center;
	//margin: 5px;
}
//.form_article_div_stock {
//	width:50px;
//	display: inline-block;
//	/*font-weight: bold;*/
//	text-align: center;
//	font-size:0.9em;
//}
.rend_invisible_label {
 	display:none;
   /*font-size:0.1em;
    height:0px;
    width:0px;*/
}
.rend_invisible_input {
	display:none;
    /*font-size:0.1em;
    height:0px;
    width:0px;
	border-style:none;
	resize:none;*/
}
/*// MARQUE_CATEGORIE_SAISON_TYPE_TVA_TAILLE_GRILLE //////////////////////////////////////////////////////////////////////////////*/
