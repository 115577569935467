/*// CARNET_ADRESSE //////////////////////////////////////////////////////////////////////////////*/
.form_adresse_label_type_adresse,
.form_mail_label_type_mail,
.form_telephone_label_type_num {
	vertical-align:top;
	width:340px;/*380px;*/
	font-style:italic;
	//margin-right:10px;
	//margin-left:10px;
}
.form_adresse_input_type_adresse,
.form_telephone_input_type_num,
.form_mail_input_type_mail {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:80px;/*180px;*/
	text-align:center; 
	font-style:italic;
}
.form_adresse_label_voie1,
.form_adresse_label_voie2,
.form_adresse_label_cp,
.form_adresse_label_ville,
.form_telephone_label_tel,
.form_mail_label_mail {
	vertical-align:top;
	width:340px;/*380px;*/
	//margin-right:10px;
	//margin-left:10px;
}
.form_adresse_input_voie1,
.form_adresse_input_voie2,
.form_adresse_input_ville,
.form_mail_input_mail {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:160px;/*180px;*/
	text-align:center; 
}
.form_telephone_input_tel,
.form_adresse_input_cp {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:110px;/*180px;*/
	text-align:center; 
}
#form_client_div_bas,
#form_fournisseur_div_bas {
	border-collapse:separate;
	border-spacing:43px 0px;
}
#form_div_bas_gauche,
#form_div_bas_droit {
	.form_div_fond;
	/*width:340px;
	display:table-cell;
	background:@bleu_clair_2;
	padding:10px;*/
}
#form_client_div_haut,
#form_fournisseur_div_haut {
	border-collapse:separate;
	border-spacing:29px 0px;
}
#form_client_div_haut_gauche,
#form_client_div_haut_droit,
#form_fournisseur_div_haut_gauche,
#form_fournisseur_div_haut_droit {
	width:360px;
	display:table-cell;
	padding:10px;
}


/*// CARNET_ADRESSE //////////////////////////////////////////////////////////////////////////////*/