/*// PC //////////////////////////////////////////////////////////////////////////////*/
.form_pc_label_compte,
.form_pc_label_libelle {
	vertical-align:top;
	width:430px;
}
.form_pc_input_compte,
.form_pc_input_libelle{
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	text-align:center; 
}
.form_pc_input_compte {
	width:120px;
}
.form_pc_input_libelle {
	width:250px;
}
#input_pc_recherche_compte {
    margin:0 10px;
}

// SELECTION PC POUR PDF ///////////////////////////////////////////////////////////////////////
.form_pc_label_id_compte {
	vertical-align:top;
	width:340px;
}
.form_pc_input_id_compte{
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:90px;
	text-align:center; 
}
// SELECTION PC POUR PDF ///////////////////////////////////////////////////////////////////////

/*// PC //////////////////////////////////////////////////////////////////////////////*/
