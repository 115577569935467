/*// OP COMM //////////////////////////////////////////////////////////////////////////////*/
.form_op_comm_label_remise_pour_cent_global,
.form_op_comm_label_remise_euros_global,
.form_op_comm_label_operation,
.form_op_comm_label_description,
.form_op_comm_label_media,
.form_op_comm_label_date_debut,
.form_op_comm_label_date_fin {
   display: inline-block;
	vertical-align:top;
	width:400px;
	//margin-right:10px;
	//margin-left:10px;
}
.form_op_comm_input_remise_pour_cent_global,
.form_op_comm_input_remise_euros_global,
.form_op_comm_input_operation,
.form_op_comm_input_description,
.form_op_comm_input_media,
.form_op_comm_input_date_debut,
.form_op_comm_input_date_fin {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
}
.form_op_comm_input_operation,
.form_op_comm_input_description,
.form_op_comm_input_media {
  width: 220px;
}
.form_op_comm_input_remise_pour_cent_global,
.form_op_comm_input_remise_euros_global,
.form_op_comm_input_date_debut,
.form_op_comm_input_date_fin {
  width: 80px;
}



/*#form_client_div_bas {
	border-collapse:separate;
	border-spacing:43px 0px;
}*/
//#form_div_bas_gauche,
//#form_div_bas_droit {
//	.form_div_fond;
	/*width:340px;
	display:table-cell;
	background:@bleu_clair_2;
	padding:10px;*/
//}
#form_op_comm_div_haut {
	border-collapse:separate;
	border-spacing:3px 0px;
}
#form_op_comm_div_haut_gauche,
#form_op_comm_div_haut_droit {
	width:410px;
	display:table-cell;
	padding:5px;
}


	//formulaire articles
.form_op_comm_label_id_op {
	vertical-align:top;
	width:280px;
	text-align:left;
}
.form_op_comm_input_id_op {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:150px;//250px;
	text-align:center; 
}

/*// OP COMM //////////////////////////////////////////////////////////////////////////////*/
