/*// NUM ECRIT TYPE //////////////////////////////////////////////////////////////////////////////*/
.form_num_ecrit_type_label_nom,
.form_num_ecrit_type_label_formule{
	vertical-align:top;
	width:430px;
	margin-right:10px;
	margin-left:10px;
}
.form_num_ecrit_type_input_nom,
.form_num_ecrit_type_input_formule{
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  width: 210px;
  text-align: center;
}
/*// NUM ECRIT TYPE //////////////////////////////////////////////////////////////////////////////*/
