/*// ARTICLE //////////////////////////////////////////////////////////////////////////////*/
.form_article_titre {
	color:@bleu_fonce_1;
	text-align:center;
}
.form_article_div1 label > span {
	font-weight:600;
	display:inline-block;
	width:90px;
	text-align:right;
	margin-right:10px;
}
.form_article_div1 {
	font-size:1.2em;
	margin:auto;
	border:2px solid @bleu_1;
	background-color:@white;
	width:900px;//880
}
.form_article_div2 {
	margin:auto;
	margin-bottom:5px;
	border:1px solid @bleu_1;
	padding:5px;
	width:850px;//830px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_article_div3 {
	margin:10px;
	vertical-align:super;
	display:table;
	border-spacing:10px 0;
}
.form_article_div3 ul li {
	/* messages du formulaire en rouge */
	color:@rouge_sombre;
}
.form_article_div_gauche,
.form_article_div_centre,
.form_article_div_droit {
	display:table-cell;
	border:1px solid lightgrey;
}
.form_article_div_gauche {
	width:280px;//260px;
}
.form_article_div_droit {
	width:260px;
}
.form_article_div_centre {
	width:230px;
}
.form_article_div_gauche,
.form_article_div_centre,
.form_article_div_droit {
	padding:20px 0px;
}
.background_yellow {
	background-color:yellow;
}
.fond_bleu {
	background-color:@bleu_clair_6;
}

//RECHERCHE
.form_article_div1 #form_article_module_recherche, 
.form_ventes_div1 #form_article_module_recherche {
	h5 {
		text-align:center;
		color:#21648d;
	}
	.form_article_div2,
	.form_ventes_div2{
		color:#21648d;
		height:50px;
		text-align:center;
		display:table;
	}
		label {
		display:table-cell;
		width:50px;
	}
	.form_recherche_label_num {
		//display:table-cell;
		width:120px;
		padding-right:20px;
		span {
			width:70px;
			//font-size:0.8em;
			//text-align:center;
			//margin-right:0px;
		}
		#input_article_rech_num	{
			line-height:0.8;
			font-size:0.9em;
			width:70px;
			height:20px;
			margin-right:5px;
			vertical-align:top;
			text-align:center;
		}
	}
	span {
		width:50px;
		font-size:0.8em;
		text-align:center;
		margin-right:0px;
	}
}
[id^="div_taille_stock_"] {
	margin:4px 0;
	input, select {
		margin:0 5px;	
	}
}

/*#chkbox_article_reception {
	display:inline-block;
	//margin-right:60px;
	//margin-left:30px;
	vertical-align:super;	
}*/
/*// ARTICLE //////////////////////////////////////////////////////////////////////////////*/


/*// COMMANDES //////////////////////////////////////////////////////////////////////////////*/
.form_commandes_label_id_m_ref_date_m,
.form_commandes_label_ref_cde,
.form_commandes_label_montant_ht,
.form_commandes_label_date  {
   display: inline-block;
	vertical-align:top;
	width:350px;
	margin-right:10px;
	margin-left:10px;
}
.form_commandes_input_ref_cde,
.form_commandes_input_montant_ht,
.form_commandes_input_date {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
  width: 150px;
}
.form_commandes_input_id_m_ref_date_m {
  display:inline-block;
  margin:0 auto;
  margin-bottom:5px;
  height:@input_height_2;
  font-size:1em;
  text-align:center;
  width:300px;
}
#commande-creer-form .form_fournisseur_label_id_marque,
#commande-modifier-form .form_fournisseur_label_id_marque {
   display: inline-block;
	vertical-align:top;
	width:350px;
	margin-right:10px;
	margin-left:10px;
}
#commande-creer-form .form_fournisseur_input_id_marque,
#commande-modifier-form .form_fournisseur_input_id_marque {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
  width: 150px;
}
.form_article_commande_input_quantite {
  //display: inline-block;
  //margin: 0 auto;
  height:@input_height_3;
  font-size: 1em;
  text-align: center;
  width: 35px;
}
/*#div_id_m_ref_date_m {
	margin-top:10px;
	margin-right:20px;
	text-align:right;
}*/
#retour_a_liste_commandes {
	cursor: pointer;
}
#form_div_commandes_lister #form_a_commandes_lister {
  margin-left: 330px;
  cursor: pointer;
}
/*// COMMANDES //////////////////////////////////////////////////////////////////////////////*/
/*// RECEPTIONS //////////////////////////////////////////////////////////////////////////////*/
.form_receptions_label_id_m_ref_date_m,
.form_receptions_label_ref_recept,
.form_receptions_label_montant_ht,
.form_receptions_label_date  {
   display: inline-block;
	vertical-align:top;
	width:350px;
	margin-right:10px;
	margin-left:10px;
}
.form_receptions_input_ref_recept,
.form_receptions_input_montant_ht,
.form_receptions_input_date {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
  width: 150px;
}
.form_receptions_input_id_m_ref_date_m {
  display:none;//inline-block;
  margin:0 auto;
  margin-left:425px;
  margin-bottom:5px;
  height:@input_height_2;
  font-size:1em;
  text-align:center;
  width:300px;
}
#reception-creer-form .form_fournisseur_label_id_marque,
#reception-modifier-form .form_fournisseur_label_id_marque {
   display: inline-block;
	vertical-align:top;
	width:350px;
	margin-right:10px;
	margin-left:10px;
}
#reception-creer-form .form_fournisseur_input_id_marque,
#reception-modifier-form .form_fournisseur_input_id_marque {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
  width: 150px;
}
.form_article_reception_input_quantite {
  //display: inline-block;
  //margin: 0 auto;
  height:@input_height_3;
  font-size: 1em;
  text-align: center;
  width: 35px;
}
/*#div_id_m_ref_date_m {
	margin-top:10px;
	margin-right:20px;
	text-align:right;
}*/
#retour_a_liste_receptions {
	cursor: pointer;
}
#form_div_receptions_lister #form_a_receptions_lister {
  margin-left: 330px;
  cursor: pointer;
}
/*// RECEPTIONS //////////////////////////////////////////////////////////////////////////////*/

/*// RETOURS //////////////////////////////////////////////////////////////////////////////*/
.form_retours_label_id_m_ref_date_m,
.form_retours_label_ref_retour,
.form_retours_label_montant_ht,
.form_retours_label_date  {
   display: inline-block;
	vertical-align:top;
	width:350px;
	margin-right:10px;
	margin-left:10px;
}
.form_retours_input_ref_retour,
.form_retours_input_montant_ht,
.form_retours_input_date {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
  width: 150px;
}
.form_retours_input_id_m_ref_date_m {
  display:none;//inline-block;
  margin:0 auto;
  margin-left:450px;
  margin-bottom:5px;
  height:@input_height_2;
  font-size:1em;
  text-align:center;
  width:280px;
  
}
#retour-creer-form .form_fournisseur_label_id_marque,
#retour-modifier-form .form_fournisseur_label_id_marque {
   display: inline-block;
	vertical-align:top;
	width:350px;
	margin-right:10px;
	margin-left:10px;
}
#retour-creer-form .form_fournisseur_input_id_marque,
#retour-modifier-form .form_fournisseur_input_id_marque {
  display: inline-block;
  margin: 0 auto;
  height:@input_height_2;
  font-size: 1.1em;
  text-align: center;
  width: 150px;
}
.form_article_retour_input_quantite {
  //display: inline-block;
  //margin: 0 auto;
  height:@input_height_3;
  font-size: 1em;
  text-align: center;
  width: 35px;
}
/*#div_id_m_ref_date_m {		// commenter les 2 autres (cdes et réceptions) ?
	margin-top:10px;
	margin-right:20px;
	text-align:right;
}*/
#a_vers_commande_creer,
#retour_a_liste_retours {
	cursor: pointer;
}
#form_div_retours_lister #form_a_retours_lister {
  margin-left: 330px;
  cursor: pointer;
}

/*// RETOURS //////////////////////////////////////////////////////////////////////////////*/