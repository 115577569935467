/*// GS CAT //////////////////////////////////////////////////////////////////////////////*/
.form_gs_cat_label_num_ordre_cat {
	vertical-align:top;
	width:120px;
	margin-right:10px;
	margin-left:10px;
}
.form_gs_cat_label_cat {
    display: inline-block;
	width:130px;//170px;
    margin-top: 5px;
	margin-left:10px;
    vertical-align:top;    
}
.form_gs_cat_select_cat {
    //display: inline-block;
    font-size: 1.1em;
    height: @input_height_2;//30px;
    margin: 0 auto;
    text-align: center;
    width: 130px;//170px;
}


.form_gs_enreg_div_JDDN .form_categorie_fournisseur_label_id_cat {
    display: inline-block;
	width:130px;//170px;
    margin-top: 5px;
	margin-left:10px;
    vertical-align:top;    
    text-align: center;
}
.form_gs_enreg_div_JDDN .form_categorie_fournisseur_input_id_cat {
    //display: inline-block;
    font-size: 1.1em;
    height: @input_height_2;//30px;
    margin: 0 auto;
    text-align: center;
    width: 130px;//170px;
}


.form_gs_cat_input_cat,
.form_gs_cat_input_num_ordre_cat {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;//30px;
	font-size:1.1em; 
	width:70px;
	text-align:center; 
}
/*// GS CAT //////////////////////////////////////////////////////////////////////////////*/
