/*// BASE GESTION //////////////////////////////////////////////////////////////////////////////*/
                
/*couleurs dépendantes*/
@bleu_fonce_1: darken(@bleu_fonce_2, 20%);	/*color titre*/
@bleu_fonce_3: darken(@bleu_fonce_2, 42%);	/**/
@bleu_fonce_4: darken(@bleu_fonce_2, 15%);	/**/
@bleu_1:       lighten(@bleu_fonce_2, 20%);	/*border container*/
@bleu_clair_1: lighten(@bleu_fonce_2, 30%);	/*th border, bg, bg date, bg foncé*/
@bleu_clair_6: lighten(@bleu_fonce_2, 31%);	
@bleu_clair_7: lighten(@bleu_fonce_2, 36%);	
@bleu_clair_2: lighten(@bleu_fonce_2, 42%);	/*odd; jour non ouvré*/
@bleu_clair_3: lighten(@bleu_fonce_2, 45%); /*bg container*/
@bleu_clair_4: lighten(@bleu_fonce_2, 45%);	/*even*/
@bleu_clair_5: lighten(@bleu_fonce_2, 80%);	/*even*/

@vert: green;
@rouge: #ff0000;
@rouge_sombre: #ca0000;
@white: #ffffff;
@black: #000000;
@rose: #ffe8e8;				                /*jour férié*/
@gris: #808080;				                /*border container*/
@gris_fonce: #464646;	    	            /*color table th */
@vert_fluo: #00ff40;
@bg_popover_title:#ffe2c6;
@bg_popover_content:#fffdfb;
@color_i_info:#ffba75;
@color_btn_login_out:#598BF7;

@input_height:25px;
@input_height_2:22px;
@input_height_3:20px;

@line-height_1:1;
@line-height_2:0.9;
.bg_button (@couleur) {
	background-image: linear-gradient(to bottom, @white 5px, @couleur 100%);
}
.bg_button_hover (@couleur) {
	background-image: linear-gradient(to top, @white 5px, @couleur 100%);
}
.bg_button_2 (@couleur) {
	background-color: @couleur;
}
.bg_body (@col1, @col2) {
	/*background-image: linear-gradient(@col1 5px, @col2 70%);*/
		/*2017-02-11*/
	background-image: linear-gradient(@col1 5px, @col2 98%);

    background-repeat: repeat-x;
	min-height:1300px;
}
.bg_navbar (@col1, @col2) {
    background-image: linear-gradient(@col1 5px, @col2 70%);
    background-repeat: repeat-x;
}
body {
	/*.bg_body (@bleu_fonce_4, @bleu_fonce_3);*/
	    /*2017-02-12*/
	.bg_body (@bleu_clair_5, @bleu_clair_2);
	font-size: 100%;
	padding-top: 60px;
	padding-bottom: 40px;
	/*background-image: linear-gradient(#4369AF 5px, #111C2F 70%);
    background-repeat: repeat-x;*/
	//.bg_body (#37568E, #111C2F);
	//min-height:1300px;
}
.navbar-inverse {
    .bg_navbar (@bleu_fonce_2, @bleu_fonce_3);
    //.bg_navbar (#4369AF, #111C2F);    /*A GARDER*/
    /*background-image: linear-gradient(to bottom, #3c3c3c 0px, #222 100%);
    /*background-image: linear-gradient(#001C55 20px, #003AA6 100%);*/
    /*background-image: linear-gradient(#4369AF 5px, #111C2F 70%);
    background-repeat: repeat-x;*/
}
.nav li {
	font-size:1.2em;
}
.btn_input_vente_enregistrer,
.btn_input_ventes_jour_lister,
#dropdownMenu1 {
	.bg_button(@bleu_clair_1);
}
#btn_login, #btn_logout {
	.bg_button_2(@bleu_fonce_1);
	color:@bleu_1;
	margin-left:140px;
}
#btn_login:hover, #btn_logout:hover {
	color:@white;
}
td a {
	color:@gris;
}
td a:hover {
	color:@black;
}
.font_bold {
	font-weight:bold;
}
.font_italic {
	font-style:italic;
}
.width_340 {
  width: 340px !important;
}
.width_300 {
  width: 300px !important;
}
.width_280 {
  width: 280px !important;
}
.width_120 {
	width:120px !important;
}
.width_90 {
	width:90px;
}
.width_80 {
	width:80px !important;
}
.width_60 {
	width:60px !important;
}
.width_50 {
	width:50px !important;
}
.width_40 {
	width:40px !important;
}
.width_35 {
	width:35px !important;
}
.border_top {
	border-top:2px solid @bleu_1;
}
.border_bottom {
	border-bottom: 2px solid @bleu_1;
}
.color_red {
	color:@rouge_sombre;
}
.color_green {
	color:@vert;
}
.deco_underline {
	text-decoration:underline;
}
.font_size_1_5 {
	font-size:1.5em;
}
.font_size_1_4 {
	font-size:1.4em;
}
.font_size_1_3 {
	font-size:1.3em;
}
.font_size_1_2 {
	font-size:1.2em;
}
.font_size_1_1 {
	font-size:1.1em;
}
.font_size_0_9 {
	font-size:0.9em;
}
.font_size_0_8 {
	font-size:0.8em;
}
.popover {
    z-index: 9999;
    position: fixed;
    max-width:25%;
}
.popover_title {
	background:@bg_popover_title;
	text-align:center;
	font-weight:bold;
}
.popover_content {
	background:@bg_popover_content;
	font-size:1.2em;
	text-align:justify;
}
.display_none {
	display:none;
}
.text_align_center {
	text-align:center;
}
.text_align_right {
	text-align:right;
}
.text_align_left {
	text-align:left !important;
}
.float_left {
	float:left;
}
.float_right {
	float:right;
}
.cursor_pointer {
	cursor:pointer;
}
.cadre_rouge {
	border: 1px solid @rouge;
}
.cadre_rouge_2{
	border: 2px solid @rouge_sombre !important;
}
.no_wrap {
	white-space:nowrap;
}
.i_info {
	border: 1px solid #fff;
	color: @color_i_info !important;
	cursor:pointer;
}
#form_op_comm_id_description_span_plus.i_plus,
#form_fournisseur_id_marque_span_plus.i_plus,
#form_categorie_fournisseur_id_cat_span_plus.i_plus,
#form_taux_tva_id_nom_span_plus.i_plus,
#form_grille_taille_id_nom_span_plus.i_plus,
#form_type_article_id_nom_span_plus.i_plus,
#form_cat_article_id_nom_span_plus.i_plus,
#form_saison_id_nom_span_plus.i_plus,
#form_marque_id_nom_span_plus.i_plus {
	border:1px solid #fff;
	color:@bleu_1 !important;
	cursor:pointer;
	padding:0px;
	margin-right:0px;
	width:20px;
}
.padding_left_90 {
    padding-left:90px;
}
.padding_left_120 {
    padding-left:120px;
}
#compte_a_rebours {
	color:black;
	float:right;
	width:30px;
}
.btn_annuler {
	width:50px !important;
	margin-left:20px;
	float:left;
	padding:0px;
}
#button_on_click_add_NLDC_Ecriture,
#button_on_click_add_NLDC,
#button_on_click_add_taille_a_grille_taille,
#button_on_click_add_taille_q_recept,
#button_on_click_add_adresse,
#button_on_click_add_mail,
#button_on_click_add_telephone {
	.bg_button(@bleu_clair_1);
	height:@input_height_2;
	line-height:1;
	
	font-style:italic;
	font-size:0.9em;//1em;
}
#button_on_click_add_NLDC_Ecriture:hover,
#button_on_click_add_NLDC:hover,
#button_on_click_add_taille_a_grille_taille:hover,
#button_on_click_add_taille_q_recept:hover,
#button_on_click_add_adresse:hover,
#button_on_click_add_telephone:hover,
#button_on_click_add_mail:hover {
	.bg_button_hover(@bleu_1);	
	background-position:0px;
}
.form_div_fond {
	width:340px;
	display:table-cell;
	background:@bleu_clair_2;
	padding:10px;
}
/*// BASE GESTION //////////////////////////////////////////////////////////////////////////////*/

/*// DROPDOWN //////////////////////////////////////////////////////////////////////////////*/
.divider_siope {
    height: 2px;//1px;
    margin: 2px 0;//9px 0;//0px 50px;
    overflow: hidden;
    background-color: #e5e5e5;//@bleu_fonce_2;//@bleu_1;//
}
.dropdown-header_siope {
    display: block;
    padding: 0px 20px;//3px 20px;
    font-size: 12px;
    line-height: 1.428571429;
    color: #999;//@bleu_fonce_1;//
    //font-weight:bold;
}

/*// DROPDOWN //////////////////////////////////////////////////////////////////////////////*/
