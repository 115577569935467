/*// VENTES //////////////////////////////////////////////////////////////////////////////*/
//VENTE SIMPLE
.form_vente_label_date,
.form_vente_label_montant {
	vertical-align:top;
	width:320px;
	margin-right:10px;
	margin-left:10px;
}
.form_vente_input_montant,
.form_vente_input_date {
	display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:80px;
	text-align:center; 
}//VENTE SIMPLE






//VENTES
.form_ventes_div1 .form_ventes_label_date,
.form_creances_div1 .form_ventes_label_date {
	//vertical-align:top;
	width:80px;
	//text-align:center;	
	display:table-cell;
	span {
		width:80px;
		text-align:center;
		margin-right:0px;
	}
}
.form_ventes_div1 .form_ventes_label_montant_ttc,
.form_creances_div1 .form_ventes_label_montant_ttc {
	//vertical-align:top;
	width:70px;
	//text-align:center;	
	display:table-cell;
	span {
		width:70px;
		text-align:center;
		margin-right:0px;
	}
}
.form_ventes_div1 .form_ventes_label_montant_ht,
.form_creances_div1 .form_ventes_label_montant_ht {
	width:70px;
	display:table-cell;
	span {
		width:70px;
		text-align:center;
		margin-right:0px;
		font-size:0.9em;
	}
}
.form_ventes_div1 .form_ventes_label_montant_tva,
.form_creances_div1 .form_ventes_label_montant_tva {
	width:70px;
	display:table-cell;
	span {
		width:70px;
		text-align:center;
		margin-right:0px;
		font-size:0.9em;
	}
}
.form_ventes_div1 .form_ventes_label_vente_ht,
.form_creances_div1 .form_ventes_label_vente_ht {
	width:70px;
	display:table-cell;
	span {
		width:40px;
		text-align:center;
		margin-right:0px;
		font-size:0.8em;
		font-weight:normal;
	}
}
.form_ventes_input_date {
	margin: 0 auto;
	height:@input_height_2;
	font-size:1em;
	font-weight:bold;
	color:@rouge_sombre;
	width:80px;
	text-align:center;	
}

.form_ventes_input_montant_ttc {
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em;
	font-weight:bold;
	width:60px;
	text-align:center;	
}
.form_ventes_input_montant_ht {
	margin: 0 auto;
	height:@input_height_2;
	font-size:0.9em; 
	width:50px;
	text-align:center;	
}
.form_ventes_input_montant_tva {
	margin: 0 auto;
	height:@input_height_2;
	font-size:0.9em; 
	width:50px;
	text-align:center;	
}
.form_ventes_input_vente_ht {
	margin: 0 auto;
	height:@input_height_2;
	//font-size:0.9em; 
	width:40px;
	//text-align:center;
}//VENTES
//VENTES DETAIL
.form_ventes_div1 .form_ventes_detail_label_rrr_pc,
.form_ventes_div1 .form_ventes_detail_label_rrr_euros,
.form_ventes_div1 .form_ventes_detail_label_prix_remise{
	//vertical-align:top;
	width:50px;
	display:table-cell;
	span {
		width:50px;
		margin-right:0px;
		text-align:center; 
	}
}
.form_ventes_div1 .form_ventes_detail_label_montant_ligne,
.form_ventes_div1 .form_ventes_detail_label_montant_ligne_ht,
.form_ventes_div1 .form_ventes_detail_label_montant_ligne_tva {
	//vertical-align:top;
	width:50px;
	//text-align:center; 
	display:table-cell;
	span {
		width:50px;
		margin-right:0px;
		text-align:center; 
	}
}
.form_ventes_div1 .form_ventes_detail_label_quantite {
	//vertical-align:top;
	width:50px;
	//text-align:center;	
	display:table-cell;
	span {
		width:50px;
		margin-right:0px;
		text-align:center; 
	}
}
.form_ventes_detail_input_prix_remise {
	//display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:0.9em; 
	width:50px;
	text-align:center;	
}
.form_ventes_detail_input_rrr_pc,
.form_ventes_detail_input_rrr_euros {
	//display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1em; 
	width:35px;
	text-align:center;	
}
.form_ventes_detail_input_montant_ligne,
.form_ventes_detail_input_montant_ligne_ht,
.form_ventes_detail_input_montant_ligne_tva {
	//display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:50px;
	text-align:center;	
}
.form_ventes_detail_input_quantite {
	//display:inline-block;
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:40px;
	text-align:center;	
}//VENTES DETAIL
//VENTES PAIEMENT
.form_ventes_paiement_label_montant_verse {
	width:70px;
	display:table-cell;
	span {
		width:70px;
		text-align:center;
		margin-right:0px;
	}
}
.form_ventes_paiement_label_date {
	width:70px;
	display:table-cell;
	span {
		width:70px;
		text-align:center;
		margin-right:0px;
	}
}
.form_ventes_paiement_input_montant_verse {
	display:table-cell;
	margin: auto 10px;//15px
	height:@input_height_2;
	font-size:1.1em; 
	width:100px;//70px;
	text-align:center;
}
.form_ventes_paiement_input_date {
	display:table-cell;
	margin: auto 10px;//15px
	height:@input_height_2;
	font-size:0.9em; 
	width:100px;//70px;
	text-align:center;	
}
//VENTES PAIEMENT
//VENTES MODE PAIEMENT
.form_mode_paiement_label_mode_paiement {
	width:70px;
	display:table-cell;
	span {
		width:70px;
		text-align:center;
		margin-right:0px;
	}
}
.form_mode_paiement_label_id_mode {
	width:70px;
	display:table-cell;
	span {
		width:70px;
		text-align:center;
		margin-right:0px;
	}
}
.form_mode_paiement_input_mode_paiement {
	margin: 0 auto;
	height:@input_height_2;
	font-size:1.1em; 
	width:50px;
	text-align:center;	
}
.form_mode_paiement_input_id_mode {
	display:table-cell;
	margin: auto 10px;//15px
	height:@input_height_2;
	font-size:0.9em; 
	width:100px;//70px;
	text-align:center;	
}//VENTES MODE PAIEMENT



//PHTML
.form_ventes_titre,
.form_creances_titre {
	color:@bleu_fonce_1;
	text-align:center;
}
.form_ventes_div1 label > span,
.form_creances_div1 label > span {
	font-weight:600;
	display:inline-block;
	width:90px;
	text-align:right;
	margin-right:10px;
}
.form_ventes_div1,
.form_creances_div1 {
	font-size:1.2em;
	margin:auto;
	border:2px solid @bleu_1;
	background-color:@white;
	width:900px;//880
}
.form_ventes_div2,
.form_creances_div2 {
	margin:auto;
	margin-bottom:5px;
	border:1px solid @bleu_1;
	padding:5px;
	width:850px;//830px;
	text-align:center;
	background-color:@bleu_clair_3;
}
.form_ventes_div3,
.form_creances_div3 {
	margin:10px;
	vertical-align:super;
	display:table;
	border-spacing:10px 0;
}
.form_ventes_div3 ul li,
.form_creances_div3 ul li {
	/* messages du formulaire en rouge */
	color:@rouge_sombre;
}
//PHTML



//BLOCS
.form_ventes_div_haut,
.form_ventes_div_milieu {
	border:1px solid lightgrey;
	display:table;
	padding:10px 0px;
}
.form_ventes_div_haut {
	width:800px;
	//text-align:right;
}
.form_ventes_div_milieu {
	width:800px;
	//text-align:right;
}
.form_ventes_div_haut_gauche,//
.form_ventes_div_haut_droit,//

.form_ventes_div_milieu_gauche,
.form_ventes_div_milieu_droit {
	border:1px solid lightgrey;
	display:table-cell;
}
.form_ventes_div_haut_gauche {//
	width:580px;
	text-align:right;
	vertical-align:top;
}
.form_ventes_div_haut_droit {//
	width:190px;
	text-align:right;
	vertical-align:top;
}
.form_ventes_div_milieu_gauche {
	width:400px;/*470px;*/
	text-align:right;
	vertical-align:top;
}
.form_ventes_div_milieu_droit {
	width:370px !important;/*300px !important;*/
	border-spacing: 10px;
}
#form_ventes_div_montant,
#form_ventes_div_date,
#form_ventes_div_mode_paiement,

#form_creances_div_montant,
#form_creances_div_date,
#form_creances_div_mode_paiement {
    width:110px;//80px;
    display:inline-block;
    font-weight:bold;
    text-align:center;
    margin: 0px 5px;
}
#solde_precedent {
	font-weight:bold;
	color:@rouge_sombre;
	background-color:yellow;
	display:none;
}
//BLOCS





table#articles_ventes{
	font-size:1em;
	color:@bleu_fonce_1;
	border:1px solid @bleu_1;
	border-collapse: collapse;
	margin: auto;
	margin-bottom: 10px;
	width:800px;
	th {
		border:1px solid @bleu_1;
		font-weight:bold;
		padding:2px 3px;
		text-align:center;
		font-size:0.95em;
	}
	td {
		border:1px solid @bleu_1;
		font-weight:normal;
		padding:2px 3px;
		text-align:center;
}
}
.template_paiements {
	margin-bottom:5px;
}
#vente_detaxe {
	display: table-cell;
	font-size:0.8em;
	font-style:italic;
	text-align:center;
	//vertical-align:bottom;
	//cursor: pointer;
}
/*// VENTES //////////////////////////////////////////////////////////////////////////////*/





/*.form_ventes_div1 #form_ventes_module_recherche_basique .form_ventes_div2 {
		color:#21648d;
		height:50px;
		text-align:right;
}
#form_ventes_m_rech_b_client_nom {
	display:inline-block;
	margin-right:270px;
}
#form_ventes_m_rech_b_vers_m_rech_detail {
	display:inline-block;
	vertical-align:150%;
	margin-right:60px;
}
#form_ventes_m_rech_b_vers_m_rech_detail > a {
	cursor:pointer;
}
#form_ventes_m_rech_b_article_id {
	display:inline-block;
	margin-right:10px;
}
#form_ventes_m_rech_b_lancer {
	display:inline-block;
	vertical-align:30%;
	margin-right:10px;
}
#form_ventes_m_rech_b_lancer > a {
	font-size:1.1em;
	font-weight:bold;
	cursor:pointer;
}*/



// PANNEAU de RECHERCHE complet

.form_recherche_div2 .form_article_label_id {
	width:65px;
	text-align:center;
	vertical-align:bottom;
	//margin:0px 8px;
	span {
		font-size:1em;
		width:65px;
		text-align:center;
		margin-right:0px;
	}
}
.form_recherche_div2 .form_article_input_id {
	width:65px;
	height:@input_height_2;
	text-align:center; 
}
.form_recherche_div2  {
	.form_client_label_nom {
		width:90px;
		text-align:center; 
		vertical-align:bottom;
		margin: 0px 65px 0px 0px;
		//margin-right:10px;
		//margin-left:10px;
		span {
			text-align:center;	
			width:90px;
			margin-right:0px;
		}
		.form_client_input_nom {
			//display:inline-block;
			//margin: 0 auto;
			height:@input_height_2;
			font-size:1.1em; 
			width:90px;
			text-align:center; 
		}
	}
}


/*#form_ventes_m_rech_d_row1 {
	display:table-row;
}
#form_ventes_m_rech_d_lancer {
	display:table-cell;
	font-weight:bold;
	padding-right:30px;
	cursor:pointer;
}
#form_ventes_m_rech_d_lancer > a {
	font-size:1.1em;
}
#form_ventes_m_rech_d_row2 {
	display:table-row;
}
#form_ventes_m_rech_d_fermer {
	display:inline-block;
	padding-left:20px;
}
#form_ventes_m_rech_d_fermer > a {
	font-size:1.1em;
	cursor:pointer;
}*/





/*.form_ventes_div1 #form_ventes_module_recherche {
	display:none;
	h6 {
		text-align:center;
		color:#21648d;
	}
	.form_ventes_div2{
		color:#21648d;
		height:110px;
		text-align:center;
		display:table;
	}
}
#form_ventes_module_recherche .form_fournisseur_label_id_marque,
#form_ventes_module_recherche .form_type_article_label_id_nom,
#form_ventes_module_recherche .form_cat_article_label_id_cat_article {
	font-size:0.9em;
	text-align:center;
	width:90px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:90px;
		text-align:center;
		margin-right:0px;
	}
}
#form_ventes_module_recherche .form_fournisseur_input_id_marque,
#form_ventes_module_recherche .form_type_article_input_id_nom,
#form_ventes_module_recherche .form_cat_article_input_id_cat_article {
	width:90px;
	height:@input_height_2;
}


#form_ventes_module_recherche .form_taille_label_id_taille,
#form_ventes_module_recherche .form_saison_label_id_nom {
	font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:65px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:65px;
		//font-size:0.8em;
		text-align:center;
		margin-right:0px;
	}
}
#form_ventes_module_recherche .form_taille_input_id_taille,
#form_ventes_module_recherche .form_saison_input_id_nom {
	width:65px;
	height:@input_height_2;
}


#form_ventes_module_recherche .form_grille_taille_label_id_nom {
	font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:80px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:80px;
		//font-size:0.8em;
		line-height:85%;
		text-align:center;
		margin-right:0px;
	}
}
#form_ventes_module_recherche .form_grille_taille_input_id_nom {
	width:80px;
	height:@input_height_2;
}


#form_ventes_module_recherche .form_article_label_id,
#form_ventes_module_recherche .form_article_label_couleur,
#form_ventes_module_recherche .form_article_label_num_reception,
#form_ventes_module_recherche .form_article_label_stock {
	font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:65px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:65px;
		//font-size:0.9em;
		text-align:center;
		margin-right:0px;
	}
}
#form_ventes_module_recherche .form_article_input_id,
#form_ventes_module_recherche .form_article_input_couleur,
#form_ventes_module_recherche .form_article_input_num_reception,
#form_ventes_module_recherche .form_article_input_stock {
	width:65px;
	height:@input_height_2;
}

#form_ventes_module_recherche .form_article_label_prix_vente,
#form_ventes_module_recherche .form_article_label_prix_achat {
	font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:55px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:55px;
		//font-size:0.8em;
		line-height:85%;
		text-align:center;
		margin-right:0px;
	}
}
#form_ventes_module_recherche .form_article_input_prix_vente,
#form_ventes_module_recherche .form_article_input_prix_achat {
	width:55px;
	height:@input_height_2;
}


#form_ventes_module_recherche .form_article_label_ref,
#form_ventes_module_recherche .form_article_label_modele {
	font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:75px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:75px;
		//font-size:0.8em;
		text-align:center;
		margin-right:0px;
	}
}
#form_ventes_module_recherche .form_article_input_ref,
#form_ventes_module_recherche .form_article_input_modele {
	width:75px;
	height:@input_height_2;
}
*/


///////////////////////////////////////////////////////////
// PANNEAU de RECHERCHE complet dernière formule !
/*div#form_recherche {
	display:none;
}*/
#form_recherche_row1 {
	display:table-row;
}
#form_recherche_lancer {
	display:table-cell;
	font-weight:bold;
	padding-right:30px;
	cursor:pointer;
}
#form_recherche_lancer > a {
	font-size:1.1em;
}
#form_recherche_row2 {
	display:table-row;
}
#form_div_recherche_fermer {
	display:inline-block;
	padding-left:20px;
}
#form_div_recherche_fermer > a {
	font-size:1.1em;
	cursor:pointer;
}

#form_recherche {
	font-size:1.1em;
	margin:auto;
	border:2px solid @bleu_1;
	background-color:@white;
	width:900px;//880
	//display:none;
	h6 {
		text-align:center;
		color:#21648d;
	}
	.form_recherche_div2{
		color:#21648d;
		height:110px;
		text-align:center;
		display:table;
		
		margin:auto;
		margin-bottom:5px;
		border:1px solid @bleu_1;
		padding:5px;
		width:850px;//830px;
		//text-align:center;
		background-color:@bleu_clair_3;
}
}
#form_recherche .form_fournisseur_label_id_marque,
#form_recherche .form_type_article_label_id_nom,
#form_recherche .form_cat_article_label_id_cat_article {
	//font-size:0.9em;
	text-align:center;
	width:90px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:90px;
		text-align:center;
		margin-right:0px;
	}
}
#form_recherche .form_fournisseur_input_id_marque,
#form_recherche .form_type_article_input_id_nom,
#form_recherche .form_cat_article_input_id_cat_article {
	width:90px;
	height:@input_height_2;
}


#form_recherche .form_taille_label_id_taille,
#form_recherche .form_saison_label_id_nom {
	//font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:65px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:65px;
		//font-size:0.8em;
		text-align:center;
		margin-right:0px;
	}
}
#form_recherche .form_taille_input_id_taille,
#form_recherche .form_saison_input_id_nom {
	width:65px;
	height:@input_height_2;
}


#form_recherche .form_grille_taille_label_id_nom {
	//font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:80px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:80px;
		//font-size:0.8em;
		line-height:85%;
		text-align:center;
		margin-right:0px;
	}
}
#form_recherche .form_grille_taille_input_id_nom {
	width:85px;
	height:@input_height_2;
}

#form_recherche .form_article_label_id,
#form_recherche .form_article_label_couleur,
#form_recherche .form_article_label_num_reception,
#form_recherche .form_article_label_stock {
	//font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:65px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:65px;
		//font-size:0.9em;
		text-align:center;
		margin-right:0px;
	}
}
#form_recherche .form_article_input_id,
#form_recherche .form_article_input_couleur,
#form_recherche .form_article_input_num_reception,
#form_recherche .form_article_input_stock {
	width:65px;
	height:@input_height_2;
}

#form_recherche .form_article_label_prix_vente,
#form_recherche .form_article_label_prix_achat {
	//font-size:1em;//0.9em;
	text-align:center;
	//display:table-cell;
	width:55px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:55px;
		//font-size:0.8em;
		line-height:40%;
		text-align:center;
		margin-right:0px;
	}
}
#form_recherche .form_article_input_prix_vente,
#form_recherche .form_article_input_prix_achat {
	width:55px;
	height:@input_height_2;
}


#form_recherche .form_article_label_ref,
#form_recherche .form_article_label_modele {
	//font-size:0.9em;
	text-align:center;
	//display:table-cell;
	width:75px;
	vertical-align:bottom;
	margin:0px 8px;
	span {
		width:75px;
		//font-size:0.8em;
		text-align:center;
		margin-right:0px;
	}
}
#form_recherche .form_article_input_ref,
#form_recherche .form_article_input_modele {
	width:75px;
	height:@input_height_2;
}
#form_div_recherche_ouvrir {
	#form_a_recherche_ouvrir {
		margin-left:330px;
		cursor:pointer;
	}
}
// PANNEAU de RECHERCHE complet dernière formule !
///////////////////////////////////////////////////////////





